import React, { useState } from "react";
import { chargeUser, formattedDay } from "../utils";

const EventPayForm = ({ isVisible, onClose, eventData }) => {
  const [amountType, setAmountType] = useState("option");
  const [showCreditCardForm, setShowCreditCardForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    address: "",
    city: "",
    state: "",
    zipCode: "",
    amount:
      eventData.__component === "event.single-payment-link"
        ? eventData.Amount
        : "",
    // Credit card fields
    cardOwnerName: "",
    cardNumber: "",
    cardExpMonth: "",
    cardExpYear: "",
    securityCode: "",
    billingZip: "",
    isMonthlyPayment: false,
  });

  const handleAmountClick = (amount) => {
    if (amount === "Other") {
      setAmountType(amount);
      setFormData((prevState) => ({
        ...prevState,
        amount: "",
      }));
    } else {
      setAmountType(amount);
      setFormData((prevState) => ({
        ...prevState,
        amount: amount.replace("$", ""),
      }));
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };

  const handleInitialSubmit = (e) => {
    e.preventDefault();

    const requiredFields = [
      "firstName",
      "lastName",
      "email",
      "address",
      "city",
      "state",
      "zipCode",
      "amount",
    ];

    const missingFields = requiredFields.filter((field) => !formData[field]);

    if (missingFields.length > 0) {
      setError(`Error: ${missingFields.join(", ")} is required.`);
    } else {
      setShowCreditCardForm(true);
    }
  };

  const handleFinalSubmit = async (e) => {
    e.preventDefault();
    console.log("Starting payment submission...");
    setLoading(true);
    setError(null);

    const response = await chargeUser(formData);

    if (response?.success) {
      setFormData({
        firstName: "",
        lastName: "",
        email: "",
        address: "",
        city: "",
        state: "",
        zipCode: "",
        // Credit card fields
        cardOwnerName: "",
        cardNumber: "",
        cardExpMonth: "",
        cardExpYear: "",
        securityCode: "",
        billingZip: "",
        isMonthlyPayment: false,
      });
      setTimeout(() => {
        onClose();
      }, 2000);
      setSuccess(true);
    } else {
      setError(response?.error);
    }

    setLoading(false);
  };
  return (
    <div
      className={`fixed z-50 inset-0 bg-[rgba(0,42,87,0.25)] transition-opacity ${
        isVisible ? "opacity-100" : "opacity-0 pointer-events-none"
      } flex justify-center items-center z-40`}
      style={{ transition: "opacity 300ms ease-in", padding: "10px" }}
      onClick={onClose}
    >
      <div
        className={`transition-transform ${
          isVisible ? "translate-y-0" : "translate-y-full"
        } bg-white rounded-2xl shadow-lg w-full max-w-4xl relative border border-neutral-300 volunteer-form overflow-y-auto`}
        style={{
          transition: "transform 300ms ease-in",
          maxHeight: "95vh",
        }}
        onClick={(e) => e.stopPropagation()}
      >
        <style>
          {`
            .volunteer-form {
              padding: 60px 100px;
            }
            @media (max-width: 768px) {
              .volunteer-form {
                padding: 20px 20px;
                max-height: 80vh;
                overflow-y: auto;
              }
            }
          `}
        </style>
        <div className="flex flex-col justify-between h-full">
          <div>
            <h1
              className="text-left"
              style={{
                fontFamily: "Manrope",
                fontSize: "32px",
                fontWeight: 700,
                lineHeight: "38.4px",
                color: "rgba(0, 42, 87, 1)",
                marginBottom: "16px",
              }}
            >
              Purchase ticket
            </h1>
            <p
              className="text-left"
              style={{
                fontFamily: "Manrope",
                fontSize: "18px",
                fontWeight: 400,
                lineHeight: "25.6px",
                color: "rgba(10, 10, 11, 1)",
                marginBottom: "16px",
              }}
            >
              You can purchase your ticket below, with each ticket sold, we're
              one step closer to creating positive change in our community.
            </p>
            {success && (
              <div className="mb-6 p-4 bg-green-100 text-green-700 rounded-md">
                Thank you for your purchase! A receipt has been sent to your
                email.
              </div>
            )}

            {error && (
              <div className="mb-6 p-4 bg-red-100 text-red-700 rounded-md">
                {error}
              </div>
            )}
            <form
              onSubmit={
                showCreditCardForm ? handleFinalSubmit : handleInitialSubmit
              }
              className="space-y-4 md:space-y-8"
            >
              <div className="flex flex-col md:flex-row justify-between space-y-4 md:space-y-0 md:space-x-4">
                <div className="flex flex-col w-full md:w-1/2 space-y-2">
                  <label
                    style={{
                      fontFamily: "Manrope",
                      fontSize: "16px",
                      fontWeight: 500,
                      lineHeight: "18px",
                      color: "rgba(0, 42, 87, 1)",
                      marginBottom: "4px",
                    }}
                  >
                    First Name
                  </label>
                  <input
                    type="text"
                    className="border rounded-full p-2"
                    name="firstName"
                    value={formData.firstName}
                    onChange={handleInputChange}
                    style={{
                      width: "100%",
                      height: "62px",
                      background: "rgba(255, 255, 255, 1)",
                      border: "1px solid rgba(181, 179, 179, 1)",
                    }}
                  />
                </div>
                <div className="flex flex-col w-full md:w-1/2 space-y-2">
                  <label
                    style={{
                      fontFamily: "Manrope",
                      fontSize: "16px",
                      fontWeight: 500,
                      lineHeight: "18px",
                      color: "rgba(0, 42, 87, 1)",
                      marginBottom: "4px",
                    }}
                  >
                    Last Name
                  </label>
                  <input
                    type="text"
                    className="border rounded-full p-2"
                    name="lastName"
                    value={formData.lastName}
                    onChange={handleInputChange}
                    style={{
                      width: "100%",
                      height: "62px",
                      background: "rgba(255, 255, 255, 1)",
                      border: "1px solid rgba(181, 179, 179, 1)",
                    }}
                  />
                </div>
              </div>
              <div className="flex flex-col space-y-2">
                <label
                  style={{
                    fontFamily: "Manrope",
                    fontSize: "16px",
                    fontWeight: 500,
                    lineHeight: "18px",
                    color: "rgba(0, 42, 87, 1)",
                    marginBottom: "4px",
                  }}
                >
                  Email Address
                </label>
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  className="border rounded-full p-2"
                  style={{
                    width: "100%",
                    height: "62px",
                    background: "rgba(255, 255, 255, 1)",
                    border: "1px solid rgba(181, 179, 179, 1)",
                  }}
                />
              </div>

              {eventData.__component === "event.multi-type-payment-link" && (
                <div className="flex flex-col space-y-2">
                  <label
                    style={{
                      fontFamily: "Manrope",
                      fontSize: "16px",
                      fontWeight: 500,
                      lineHeight: "18px",
                      color: "rgba(0, 42, 87, 1)",
                      marginBottom: "4px",
                    }}
                  >
                    Amount
                  </label>
                  <select
                    onChange={(e) => handleAmountClick(e.target.value)}
                    value={amountType}
                    className="w-full md:w-auto h-[48px] border rounded-[30px] px-4 text-custom-blue focus:outline-none border-gray-400"
                  >
                    <option>Select option</option>
                    {eventData.PaymentOptions.map((paymentOption) => (
                      <option
                        key={paymentOption.id}
                        value={paymentOption.amount}
                      >
                        {paymentOption.PriceType} - ${paymentOption.amount}
                      </option>
                    ))}
                  </select>
                </div>
              )}
              <div className="flex flex-col space-y-2">
                <label
                  style={{
                    fontFamily: "Manrope",
                    fontSize: "16px",
                    fontWeight: 500,
                    lineHeight: "18px",
                    color: "rgba(0, 42, 87, 1)",
                    marginBottom: "4px",
                  }}
                >
                  Address
                </label>
                <input
                  type="text"
                  className="border rounded-full p-2"
                  name="address"
                  value={formData.address}
                  onChange={handleInputChange}
                  style={{
                    width: "100%",
                    height: "62px",
                    background: "rgba(255, 255, 255, 1)",
                    border: "1px solid rgba(181, 179, 179, 1)",
                  }}
                />
              </div>
              <div className="flex flex-col md:flex-row justify-between space-y-4 md:space-y-0 md:space-x-4">
                <div className="flex flex-col w-full md:w-1/2 space-y-2">
                  <label
                    style={{
                      fontFamily: "Manrope",
                      fontSize: "16px",
                      fontWeight: 500,
                      lineHeight: "18px",
                      color: "rgba(0, 42, 87, 1)",
                      marginBottom: "4px",
                    }}
                  >
                    City
                  </label>
                  <input
                    type="text"
                    className="border rounded-full p-2"
                    name="city"
                    value={formData.city}
                    onChange={handleInputChange}
                    style={{
                      width: "100%",
                      height: "62px",
                      background: "rgba(255, 255, 255, 1)",
                      border: "1px solid rgba(181, 179, 179, 1)",
                    }}
                  />
                </div>
                <div className="flex flex-col w-full md:w-1/2 space-y-2">
                  <label
                    style={{
                      fontFamily: "Manrope",
                      fontSize: "16px",
                      fontWeight: 500,
                      lineHeight: "18px",
                      color: "rgba(0, 42, 87, 1)",
                      marginBottom: "4px",
                    }}
                  >
                    State
                  </label>
                  <input
                    type="text"
                    className="border rounded-full p-2"
                    name="state"
                    value={formData.state}
                    onChange={handleInputChange}
                    style={{
                      width: "100%",
                      height: "62px",
                      background: "rgba(255, 255, 255, 1)",
                      border: "1px solid rgba(181, 179, 179, 1)",
                    }}
                  />
                </div>
              </div>
              <div className="flex flex-col space-y-2">
                <label
                  style={{
                    fontFamily: "Manrope",
                    fontSize: "16px",
                    fontWeight: 500,
                    lineHeight: "18px",
                    color: "rgba(0, 42, 87, 1)",
                    marginBottom: "4px",
                  }}
                >
                  Zipcode
                </label>
                <input
                  type="text"
                  name="zipCode"
                  value={formData.zipCode}
                  onChange={handleInputChange}
                  className="border rounded-full p-2"
                  style={{
                    width: "100%",
                    height: "62px",
                    background: "rgba(255, 255, 255, 1)",
                    border: "1px solid rgba(181, 179, 179, 1)",
                  }}
                />
              </div>
              {showCreditCardForm ? (
                <>
                  <div className="mt-8">
                    <h2 className="text-2xl font-bold mb-6 text-custom-blue">
                      Credit Card Information
                    </h2>
                    <div className="space-y-6">
                      <div>
                        <label className="block font-primary mb-2 text-custom-blue">
                          Cardholder Name*
                        </label>
                        <input
                          type="text"
                          name="cardOwnerName"
                          value={formData.cardOwnerName}
                          onChange={handleInputChange}
                          className="w-full h-[48px] border rounded-[30px] p-3 focus:outline-none focus:ring-2 focus:ring-custom-blue border-gray-400"
                          required
                        />
                      </div>
                      <div>
                        <label className="block font-primary mb-2 text-custom-blue">
                          Card Number*
                        </label>
                        <input
                          type="text"
                          id="card-number"
                          name="cardNumber"
                          value={formData.cardNumber}
                          onChange={handleInputChange}
                          className="w-full h-[48px] border rounded-[30px] p-3 focus:outline-none focus:ring-2 focus:ring-custom-blue border-gray-400"
                          required
                        />
                      </div>
                      <div className="grid grid-cols-2 gap-4">
                        <div>
                          <label className="block font-primary mb-2 text-custom-blue">
                            Expiration Month*
                          </label>
                          <input
                            type="text"
                            id="card-month"
                            name="cardExpMonth"
                            value={formData.cardExpMonth}
                            onChange={handleInputChange}
                            placeholder="MM"
                            maxLength="2"
                            className="w-full h-[48px] border rounded-[30px] p-3 focus:outline-none focus:ring-2 focus:ring-custom-blue border-gray-400"
                            required
                          />
                        </div>
                        <div>
                          <label className="block font-primary mb-2 text-custom-blue">
                            Expiration Year*
                          </label>
                          <input
                            type="text"
                            id="card-year"
                            name="cardExpYear"
                            value={formData.cardExpYear}
                            onChange={handleInputChange}
                            placeholder="YYYY"
                            maxLength="4"
                            className="w-full h-[48px] border rounded-[30px] p-3 focus:outline-none focus:ring-2 focus:ring-custom-blue border-gray-400"
                            required
                          />
                        </div>
                      </div>
                      <div>
                        <label className="block font-primary mb-2 text-custom-blue">
                          Security Code*
                        </label>
                        <input
                          type="text"
                          id="card-cvc"
                          name="securityCode"
                          value={formData.securityCode}
                          onChange={handleInputChange}
                          maxLength="4"
                          className="w-full h-[48px] border rounded-[30px] p-3 focus:outline-none focus:ring-2 focus:ring-custom-blue border-gray-400"
                          required
                        />
                        <p className="text-sm text-gray-500 mt-1">
                          Your security code is the 3-digit code at the end of
                          the signature field on your card's back.
                        </p>
                      </div>
                      <div>
                        <label className="block font-primary mb-2 text-custom-blue">
                          Billing Zip Code*
                        </label>
                        <input
                          type="text"
                          name="billingZip"
                          value={formData.billingZip}
                          onChange={handleInputChange}
                          className="w-full h-[48px] border rounded-[30px] p-3 focus:outline-none focus:ring-2 focus:ring-custom-blue border-gray-400"
                          required
                        />
                      </div>
                      <div className="flex items-center mt-4">
                        <input
                          type="checkbox"
                          name="isMonthlyPayment"
                          checked={formData.isMonthlyPayment}
                          onChange={handleCheckboxChange}
                          className="mr-2"
                        />
                        <label className="text-custom-blue">
                          Yes, charge me today and on the {formattedDay}{" "}
                          of every following month.
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="my-6 p-4 bg-green-100 text-green-700 rounded-md">
                    You are about to be charged ${formData.amount}
                  </div>

                  {error && (
                    <div className="mb-6 p-4 bg-red-100 text-red-700 rounded-md">
                      {error}
                    </div>
                  )}

                  <div className="flex justify-between">
                    <button
                      type="button"
                      onClick={() => setShowCreditCardForm(false)}
                      className="px-6 py-2 text-custom-blue border border-custom-blue rounded-[30px] hover:bg-gray-50"
                      disabled={loading}
                    >
                      Back
                    </button>
                    <button
                      type="submit"
                      className="px-6 py-2 bg-custom-blue text-white rounded-[30px] hover:bg-blue-700 disabled:opacity-50"
                      disabled={loading}
                    >
                      {loading ? "Processing..." : "Complete Payment"}
                    </button>
                  </div>
                </>
              ) : (
                <div>
                  <button
                    type="submit"
                    className="w-full h-[48px] bg-custom-blue text-white rounded-[30px] p-3 focus:outline-none focus:ring-2 focus:ring-custom-blue"
                  >
                    Continue to Payment
                  </button>
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventPayForm;
