import React, { useState } from "react";
import backgroundImage from "../assets/background.png";
import { chargeUser, formattedDay } from "../utils";

const Donation = () => {
  const [amountType, setAmountType] = useState("option");
  const [showCreditCardForm, setShowCreditCardForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    address: "",
    city: "",
    state: "",
    zipCode: "",
    honorType: "",
    honorName: "",
    amount: "",
    // Credit card fields
    cardOwnerName: "",
    cardNumber: "",
    cardExpMonth: "",
    cardExpYear: "",
    securityCode: "",
    billingZip: "",
    isMonthlyPayment: false,
  });

  const handleAmountClick = (amount) => {
    if (amount === "Other") {
      setAmountType(amount);
      setFormData((prevState) => ({
        ...prevState,
        amount: "",
      }));
    } else {
      setAmountType(amount);
      setFormData((prevState) => ({
        ...prevState,
        amount: amount.replace("$", ""),
      }));
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };

  const handleInitialSubmit = (e) => {
    e.preventDefault();

    const requiredFields = [
      "firstName",
      "lastName",
      "email",
      "address",
      "city",
      "state",
      "zipCode",
      "amount",
    ];

    const missingFields = requiredFields.filter((field) => !formData[field]);

    if (missingFields.length > 0) {
      setError(`Error: ${missingFields.join(", ")} is required.`);
    } else {
      setShowCreditCardForm(true);
    }
  };

  const handleFinalSubmit = async (e) => {
    e.preventDefault();
    console.log("Starting payment submission...");
    setLoading(true);
    setError(null);

    const response = await chargeUser(formData);

    if (response?.success) {
      setFormData({
        firstName: "",
        lastName: "",
        email: "",
        address: "",
        city: "",
        state: "",
        zipCode: "",
        honorType: "",
        honorName: "",
        amount: "",
        // Credit card fields
        cardOwnerName: "",
        cardNumber: "",
        cardExpMonth: "",
        cardExpYear: "",
        securityCode: "",
        billingZip: "",
        isMonthlyPayment: false,
      })
      setSuccess(true);
    } else {
      setError(response?.error);
    }

    setLoading(false);
  };

  return (
    <div
      id="donate"
      className="min-h-screen flex flex-col items-center px-4 mb-16 relative z-10"
    >
      <div
        className="relative bg-cover bg-center w-full flex items-center justify-center py-16"
        style={{
          backgroundImage: `url(${backgroundImage})`,
          marginTop: "85px",
        }}
      >
        <div className="text-center max-w-3xl mx-auto bg-opacity-80 p-8 rounded-lg">
          <div
            className="uppercase mb-2 text-center text-[16px] font-bold tracking-widest text-[#FFA500]"
            style={{ fontFamily: "Manrope" }}
          >
            Donate
          </div>
          <h1
            className="text-custom-blue text-[32px] md:text-[64px] font-bold leading-[38.4px] md:leading-[76.8px] text-center"
            style={{ fontFamily: "Manrope", fontWeight: "700px" }}
          >
            Support Our Mission
          </h1>
          <p
            className="text-black mt-4 text-[14px] md:text-[18px] font-normal leading-[20px] md:leading-[25.6px] text-center"
            style={{ fontFamily: "Manrope", fontWeight: "400px" }}
          >
            Your donation helps us drive social justice, equity, and community
            empowerment
          </p>
        </div>
      </div>

      <div className="bg-white p-8 rounded-[20px] max-w-[90%] md:max-w-[764px] w-full mx-auto mt-0 shadow-md">
        {success && (
          <div className="mb-6 p-4 bg-green-100 text-green-700 rounded-md">
            Thank you for your donation! A receipt has been sent to your email.
          </div>
        )}

        {error && (
          <div className="mb-6 p-4 bg-red-100 text-red-700 rounded-md">
            {error}
          </div>
        )}

        <form
          onSubmit={
            showCreditCardForm ? handleFinalSubmit : handleInitialSubmit
          }
          className="grid grid-cols-1 gap-7 p-4 md:p-12"
        >
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div>
              <label className="block font-primary mb-2 text-custom-blue">
                First Name*
              </label>
              <input
                type="text"
                name="firstName"
                value={formData.firstName}
                onChange={handleInputChange}
                className="w-full h-[48px] border rounded-[30px] p-3 focus:outline-none focus:ring-2 focus:ring-custom-blue border-gray-400"
                required
              />
            </div>
            <div>
              <label className="block font-primary mb-2 text-custom-blue">
                Last Name*
              </label>
              <input
                type="text"
                name="lastName"
                value={formData.lastName}
                onChange={handleInputChange}
                className="w-full h-[48px] border rounded-[30px] p-3 focus:outline-none focus:ring-2 focus:ring-custom-blue border-gray-400"
                required
              />
            </div>
          </div>
          <div>
            <label className="block font-primary mb-2 text-custom-blue">
              Email Address*
            </label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              className="w-full h-[48px] border rounded-[30px] p-3 focus:outline-none focus:ring-2 focus:ring-custom-blue border-gray-400"
              required
            />
          </div>
          <div>
            <label className="block font-primary mb-2 text-custom-blue">
              Donation Amount*
            </label>
            <div className="flex flex-wrap justify-between space-x-2 md:space-x-4">
              {["$10", "$25", "$50", "$100", "Other"].map((amount, index) => (
                <button
                  key={index}
                  type="button"
                  onClick={() => handleAmountClick(amount)}
                  className={`w-[60px] md:w-[84px] h-[48px] border rounded-[30px] ${
                    amountType === amount
                      ? "bg-custom-blue text-white"
                      : "text-custom-blue"
                  } focus:outline-none border-gray-400`}
                >
                  {amount}
                </button>
              ))}
            </div>
            <div
              className={`mt-2 transition-all duration-500 ease-in-out ${
                amountType === "Other"
                  ? "max-h-48 opacity-100"
                  : "max-h-0 opacity-0 overflow-hidden"
              }`}
            >
              <input
                type="number"
                name="amount"
                value={formData.amount}
                onChange={handleInputChange}
                placeholder="Enter Donation Amount"
                className="w-full h-[48px] border rounded-[30px] p-3 focus:outline-none focus:ring-2 focus:ring-custom-blue border-gray-400"
              />
            </div>
          </div>
          <div>
            <label className="block font-primary mb-2 text-custom-blue">
              Address*
            </label>
            <input
              type="text"
              name="address"
              value={formData.address}
              onChange={handleInputChange}
              className="w-full h-[48px] border rounded-[30px] p-3 focus:outline-none focus:ring-2 focus:ring-custom-blue border-gray-400"
              required
            />
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div>
              <label className="block font-primary mb-2 text-custom-blue">
                City*
              </label>
              <input
                type="text"
                name="city"
                value={formData.city}
                onChange={handleInputChange}
                className="w-full h-[48px] border rounded-[30px] p-3 focus:outline-none focus:ring-2 focus:ring-custom-blue border-gray-400"
                required
              />
            </div>
            <div>
              <label className="block font-primary mb-2 text-custom-blue">
                State*
              </label>
              <input
                type="text"
                name="state"
                value={formData.state}
                onChange={handleInputChange}
                className="w-full h-[48px] border rounded-[30px] p-3 focus:outline-none focus:ring-2 focus:ring-custom-blue border-gray-400"
                required
              />
            </div>
          </div>
          <div>
            <label className="block font-primary mb-2 text-custom-blue">
              Zip Code*
            </label>
            <input
              type="text"
              name="zipCode"
              value={formData.zipCode}
              onChange={handleInputChange}
              className="w-full h-[48px] border rounded-[30px] p-3 focus:outline-none focus:ring-2 focus:ring-custom-blue border-gray-400"
              required
            />
          </div>
          <div>
            <label
              className="block text-[24px] font-medium leading-[32.78px] text-left text-custom-blue mb-2"
              style={{ fontFamily: "Manrope" }}
            >
              Would you like to make your donation "In Honor of" or "In Memory
              of" someone?
            </label>
            <div className="flex flex-col space-y-4 mt-4">
              <label className="flex items-center">
                <input
                  type="radio"
                  name="honorType"
                  value="honor"
                  checked={formData.honorType === "honor"}
                  onChange={handleInputChange}
                  className="mr-2 w-[16.67px] h-[16.67px] border-[1.67px] border-gray-400"
                />
                <span
                  className="text-[16px] font-medium text-custom-blue"
                  style={{ fontFamily: "Manrope" }}
                >
                  In Honor of
                </span>
              </label>
              <label className="flex items-center">
                <input
                  type="radio"
                  name="honorType"
                  value="memory"
                  checked={formData.honorType === "memory"}
                  onChange={handleInputChange}
                  className="mr-2 w-[16.67px] h-[16.67px] border-[1.67px] border-gray-400"
                />
                <span
                  className="text-[16px] font-medium text-custom-blue"
                  style={{ fontFamily: "Manrope" }}
                >
                  In Memory of
                </span>
              </label>
            </div>
            {formData.honorType && (
              <div className="mt-4">
                <label className="block font-primary mb-2 text-custom-blue">
                  Name of Person
                </label>
                <input
                  type="text"
                  name="honorName"
                  value={formData.honorName}
                  onChange={handleInputChange}
                  className="w-full h-[48px] border rounded-[30px] p-3 focus:outline-none focus:ring-2 focus:ring-custom-blue border-gray-400"
                  required
                />
              </div>
            )}
          </div>

          {showCreditCardForm ? (
            <>
              <div className="mt-8">
                <h2 className="text-2xl font-bold mb-6 text-custom-blue">
                  Credit Card Information
                </h2>
                <div className="space-y-6">
                  <div>
                    <label className="block font-primary mb-2 text-custom-blue">
                      Cardholder Name*
                    </label>
                    <input
                      type="text"
                      name="cardOwnerName"
                      value={formData.cardOwnerName}
                      onChange={handleInputChange}
                      className="w-full h-[48px] border rounded-[30px] p-3 focus:outline-none focus:ring-2 focus:ring-custom-blue border-gray-400"
                      required
                    />
                  </div>
                  <div>
                    <label className="block font-primary mb-2 text-custom-blue">
                      Card Number*
                    </label>
                    <input
                      type="text"
                      id="card-number"
                      name="cardNumber"
                      value={formData.cardNumber}
                      onChange={handleInputChange}
                      className="w-full h-[48px] border rounded-[30px] p-3 focus:outline-none focus:ring-2 focus:ring-custom-blue border-gray-400"
                      required
                    />
                  </div>
                  <div className="grid grid-cols-2 gap-4">
                    <div>
                      <label className="block font-primary mb-2 text-custom-blue">
                        Expiration Month*
                      </label>
                      <input
                        type="text"
                        id="card-month"
                        name="cardExpMonth"
                        value={formData.cardExpMonth}
                        onChange={handleInputChange}
                        placeholder="MM"
                        maxLength="2"
                        className="w-full h-[48px] border rounded-[30px] p-3 focus:outline-none focus:ring-2 focus:ring-custom-blue border-gray-400"
                        required
                      />
                    </div>
                    <div>
                      <label className="block font-primary mb-2 text-custom-blue">
                        Expiration Year*
                      </label>
                      <input
                        type="text"
                        id="card-year"
                        name="cardExpYear"
                        value={formData.cardExpYear}
                        onChange={handleInputChange}
                        placeholder="YYYY"
                        maxLength="4"
                        className="w-full h-[48px] border rounded-[30px] p-3 focus:outline-none focus:ring-2 focus:ring-custom-blue border-gray-400"
                        required
                      />
                    </div>
                  </div>
                  <div>
                    <label className="block font-primary mb-2 text-custom-blue">
                      Security Code*
                    </label>
                    <input
                      type="text"
                      id="card-cvc"
                      name="securityCode"
                      value={formData.securityCode}
                      onChange={handleInputChange}
                      maxLength="4"
                      className="w-full h-[48px] border rounded-[30px] p-3 focus:outline-none focus:ring-2 focus:ring-custom-blue border-gray-400"
                      required
                    />
                    <p className="text-sm text-gray-500 mt-1">
                      Your security code is the 3-digit code at the end of the
                      signature field on your card's back.
                    </p>
                  </div>
                  <div>
                    <label className="block font-primary mb-2 text-custom-blue">
                      Billing Zip Code*
                    </label>
                    <input
                      type="text"
                      name="billingZip"
                      value={formData.billingZip}
                      onChange={handleInputChange}
                      className="w-full h-[48px] border rounded-[30px] p-3 focus:outline-none focus:ring-2 focus:ring-custom-blue border-gray-400"
                      required
                    />
                  </div>
                  <div className="flex items-center mt-4">
                    <input
                      type="checkbox"
                      name="isMonthlyPayment"
                      checked={formData.isMonthlyPayment}
                      onChange={handleCheckboxChange}
                      className="mr-2"
                    />
                    <label className="text-custom-blue">
                      Yes, charge me today and on the {formattedDay} of every following
                      month.
                    </label>
                  </div>
                </div>
              </div>
              <div className="flex justify-between">
                <button
                  type="button"
                  onClick={() => setShowCreditCardForm(false)}
                  className="px-6 py-2 text-custom-blue border border-custom-blue rounded-[30px] hover:bg-gray-50"
                  disabled={loading}
                >
                  Back
                </button>
                <button
                  type="submit"
                  className="px-6 py-2 bg-custom-blue text-white rounded-[30px] hover:bg-blue-700 disabled:opacity-50"
                  disabled={loading}
                >
                  {loading ? "Processing..." : "Complete Donation"}
                </button>
              </div>
            </>
          ) : (
            <div>
              <button
                type="submit"
                className="w-full h-[48px] bg-custom-blue text-white rounded-[30px] p-3 focus:outline-none focus:ring-2 focus:ring-custom-blue"
              >
                Continue to Payment
              </button>
            </div>
          )}
        </form>
      </div>
    </div>
  );
};

export default Donation;
