import React from "react";
import Footer from "./components/footer";
import Navbar from "./components/navbar";
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import Leadership from "./components/leadership";
import Contact from "./components/contactUs"
import HeroSection from "./components/heroSection";
import Volunteer from "./components/volunteer";
import Donation from "./components/donation";
import WhatWeDo from "./components/whatWeDo";
import OurMember from "./components/ourMembers";
import BecomeMember from "./components/becomeMember";
import Blog from "./components/blog";
import AboutUs from "./components/about";
import Events from "./components/events";
import EventDetails from "./components/EventDetails";
import TaskforceDetails from "./components/TaskforceDetails";

const PageTransition = ({ children }) => (
  <motion.div
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 0 }}
    transition={{ duration: 0.4 }}
    style={{ backgroundColor: 'rgba(255, 255, 255, 0.8)' }}
  >
    {children}
  </motion.div>
);

const AnimatedRoutes = () => {
  const location = useLocation();
  return (
    <AnimatePresence mode="wait">
      <Routes location={location} key={location.pathname}>
        <Route path="/" element={<PageTransition><HeroSection /></PageTransition>} />
        <Route path="/volunteer" element={<PageTransition><Volunteer /></PageTransition>} />
        <Route path="/donate" element={<PageTransition><Donation /></PageTransition>} />
        <Route path="/contact" element={<PageTransition><Contact /></PageTransition>} />
        <Route path="/whatWeDo" element={<PageTransition><WhatWeDo /></PageTransition>} />
        <Route path="/taskforces/:id" element={<PageTransition><TaskforceDetails /></PageTransition>} />
        <Route path="/ourMember" element={<PageTransition><OurMember /></PageTransition>} />
        <Route path="/becomeMember" element={<PageTransition><BecomeMember /></PageTransition>} />
        <Route path="/blog" element={<PageTransition><Blog /></PageTransition>} />
        <Route path="/aboutUs" element={<PageTransition><AboutUs /></PageTransition>} />
        <Route path="/events" element={<PageTransition><Events /></PageTransition>} />
        <Route path="/events/:id" element={<PageTransition><EventDetails /></PageTransition>} />
        <Route path="/leadership" element={<PageTransition><Leadership /></PageTransition>} />
      </Routes>
    </AnimatePresence>
  );
};

function App() {
  return (
    <Router>
      <Navbar />

      <AnimatedRoutes />

      <Footer />
    </Router>
  );
}

export default App;
