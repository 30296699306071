import React from "react";

const VolunteerForm = ({ isVisible, onClose }) => {
  return (
    <div
      className={`fixed inset-0 bg-[rgba(0,42,87,0.25)] transition-opacity ${
        isVisible ? "opacity-100" : "opacity-0 pointer-events-none"
      } flex justify-center items-center z-40`}
      style={{ transition: "opacity 300ms ease-in", padding: "10px" }}
      onClick={onClose}
    >
      <div
        className={`transition-transform ${
          isVisible ? "translate-y-0" : "translate-y-full"
        } bg-white rounded-2xl shadow-lg w-full max-w-4xl relative border border-neutral-300 volunteer-form overflow-y-auto md:overflow-hidden`}
        style={{
          transition: "transform 300ms ease-in",
          maxHeight: "95vh",
        }}
        onClick={(e) => e.stopPropagation()}
      >
        <style>
          {`
            .volunteer-form {
              padding: 20px 100px;
            }
            @media (max-width: 768px) {
              .volunteer-form {
                padding: 20px 20px;
                max-height: 80vh;
                overflow-y: auto;
              }
            }
            @media (min-width: 769px) {
              .volunteer-form {
                overflow-y: hidden;
              }
            }
          `}
        </style>
        <div className="flex flex-col justify-between min-h-[700px]">
          {/* <div>
            <h1
              className="text-left"
              style={{
                fontFamily: "Manrope",
                fontSize: "32px",
                fontWeight: 700,
                lineHeight: "38.4px",
                color: "rgba(0, 42, 87, 1)",
                marginBottom: "8px",
              }}
            >
              Fill in the form
            </h1>
            <p
              className="text-left"
              style={{
                fontFamily: "Manrope",
                fontSize: "18px",
                fontWeight: 400,
                lineHeight: "25.6px",
                color: "rgba(10, 10, 11, 1)",
                marginBottom: "16px",
              }}
            >
              Join us in making a difference in our community through your time
              and talents
            </p>
            <form className="space-y-4 md:space-y-8">
              <div className="flex flex-col md:flex-row justify-between space-y-4 md:space-y-0 md:space-x-4">
                <div className="flex flex-col w-full md:w-1/2 space-y-2">
                  <label
                    style={{
                      fontFamily: 'Manrope',
                      fontSize: '16px',
                      fontWeight: 500,
                      lineHeight: '18px',
                      color: 'rgba(0, 42, 87, 1)',
                      marginBottom: '4px',
                    }}
                  >
                    First Name
                  </label>
                  <input
                    type="text"
                    className="border rounded-full p-2"
                    style={{
                      width: '100%',
                      height: '62px',
                      background: 'rgba(255, 255, 255, 1)',
                      border: '1px solid rgba(181, 179, 179, 1)',
                    }}
                  />
                </div>
                <div className="flex flex-col w-full md:w-1/2 space-y-2">
                  <label
                    style={{
                      fontFamily: 'Manrope',
                      fontSize: '16px',
                      fontWeight: 500,
                      lineHeight: '18px',
                      color: 'rgba(0, 42, 87, 1)',
                      marginBottom: '4px',
                    }}
                  >
                    Last Name
                  </label>
                  <input
                    type="text"
                    className="border rounded-full p-2"
                    style={{
                      width: '100%',
                      height: '62px',
                      background: 'rgba(255, 255, 255, 1)',
                      border: '1px solid rgba(181, 179, 179, 1)',
                    }}
                  />
                </div>
              </div>
              <div className="flex flex-col space-y-2">
                <label
                  style={{
                    fontFamily: 'Manrope',
                    fontSize: '16px',
                    fontWeight: 500,
                    lineHeight: '18px',
                    color: 'rgba(0, 42, 87, 1)',
                    marginBottom: '4px',
                  }}
                >
                  Email Address
                </label>
                <input
                  type="email"
                  className="border rounded-full p-2"
                  style={{
                    width: '100%',
                    height: '62px',
                    background: 'rgba(255, 255, 255, 1)',
                    border: '1px solid rgba(181, 179, 179, 1)',
                  }}
                />
              </div>
              <div className="flex flex-col space-y-2">
                <label
                  style={{
                    fontFamily: 'Manrope',
                    fontSize: '16px',
                    fontWeight: 500,
                    lineHeight: '18px',
                    color: 'rgba(0, 42, 87, 1)',
                    marginBottom: '4px',
                  }}
                >
                  Address
                </label>
                <input
                  type="text"
                  className="border rounded-full p-2"
                  style={{
                    width: '100%',
                    height: '62px',
                    background: 'rgba(255, 255, 255, 1)',
                    border: '1px solid rgba(181, 179, 179, 1)',
                  }}
                />
              </div>
              <div className="flex flex-col md:flex-row justify-between space-y-4 md:space-y-0 md:space-x-4">
                <div className="flex flex-col w-full md:w-1/2 space-y-2">
                  <label
                    style={{
                      fontFamily: 'Manrope',
                      fontSize: '16px',
                      fontWeight: 500,
                      lineHeight: '18px',
                      color: 'rgba(0, 42, 87, 1)',
                      marginBottom: '4px',
                    }}
                  >
                    City
                  </label>
                  <input
                    type="text"
                    className="border rounded-full p-2"
                    style={{
                      width: '100%',
                      height: '62px',
                      background: 'rgba(255, 255, 255, 1)',
                      border: '1px solid rgba(181, 179, 179, 1)',
                    }}
                  />
                </div>
                <div className="flex flex-col w-full md:w-1/2 space-y-2">
                  <label
                    style={{
                      fontFamily: 'Manrope',
                      fontSize: '16px',
                      fontWeight: 500,
                      lineHeight: '18px',
                      color: 'rgba(0, 42, 87, 1)',
                      marginBottom: '4px',
                    }}
                  >
                    State
                  </label>
                  <input
                    type="text"
                    className="border rounded-full p-2"
                    style={{
                      width: '100%',
                      height: '62px',
                      background: 'rgba(255, 255, 255, 1)',
                      border: '1px solid rgba(181, 179, 179, 1)',
                    }}
                  />
                </div>
              </div>
              <div className="flex flex-col space-y-2">
                <label
                  style={{
                    fontFamily: 'Manrope',
                    fontSize: '16px',
                    fontWeight: 500,
                    lineHeight: '18px',
                    color: 'rgba(0, 42, 87, 1)',
                    marginBottom: '4px',
                  }}
                >
                  Zipcode
                </label>
                <input
                  type="text"
                  className="border rounded-full p-2"
                  style={{
                    width: '100%',
                    height: '62px',
                    background: 'rgba(255, 255, 255, 1)',
                    border: '1px solid rgba(181, 179, 179, 1)',
                  }}
                />
              </div>
            </form> */}
            {/* <div
              className="ngp-form"
              data-form-url="https://secure.everyaction.com/v1/Forms/SEx0iP0fvU6STObgHvKT0w2"
              data-custom-css="https://nvlupin.blob.core.windows.net/designs/CustomStylesheet_59b9b6652237f4d3f99e4a4fd4ff8226a80b0c8492a2499f229bb760a7026c18.css"
              data-fastaction-endpoint="https://fastaction.ngpvan.com"
              data-inline-errors="true"
              data-fastaction-nologin="true"
              data-mobile-autofocus="false"
            ></div> */}
          {/* </div> */}
          {/* <button
            type="submit"
            className="w-full bg-[#002A57] text-white rounded-full p-3 mt-4 h-[62px] flex items-center justify-center"
            style={{
              height: "62px",
              background: "rgba(0, 42, 87, 1)",
            }}
          >
            <span
              style={{
                lineHeight: "18px",
                fontFamily: "Manrope",
                fontSize: "16px",
                fontWeight: 500,
                textAlign: "center",
                color: "rgba(255, 255, 255, 1)",
              }}
            >
              Submit
            </span>
          </button> */}

<iframe src="https://secure.everyaction.com/SEx0iP0fvU6STObgHvKT0w2" className="w-full min-h-[700px]" />
        </div>
      </div>
    </div>
  );
};

export default VolunteerForm;
