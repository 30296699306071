// import React, { useEffect, useState } from "react";
// import { useParams, useNavigate } from "react-router-dom";
// import Newsletter from "./newsLetter";

// const TaskforceDetails = () => {
//   const navigate = useNavigate();
//   const { id } = useParams();
//   const [taskforce, setTaskforce] = useState(null);
//   const [loading, setLoading] = useState(true);

//   const handleContactUsClick = () => {
//     navigate("/contact");
//   };
//   const handleDonateClick = () => {
//     navigate("/donate");
//   };

//   useEffect(() => {
//     const fetchTaskforce = async () => {
//       try {
//         const response = await fetch(
//           `https://brave-desire-25bf4107de.strapiapp.com/api/taskforces/${id}?populate=*`,
//           {
//             headers: {
//               Authorization: `Bearer ${process.env.REACT_APP_CMS_TOKEN}`,
//             },
//           }
//         );
//         const data = await response.json();
//         setTaskforce(data.data);
//         setLoading(false);
//       } catch (error) {
//         console.error("Error fetching taskforce data: ", error);
//         setLoading(false);
//       }
//     };

//     fetchTaskforce();
//   }, [id]);

//   if (loading) {
//     return <div className="text-center mt-10">Loading...</div>;
//   }

//   if (!taskforce) {
//     return <div className="text-center mt-10">Taskforce not found.</div>;
//   }

//   const { title, description, body, title_background } = taskforce.attributes;
//   const imageFormats = title_background?.data?.attributes?.formats || {};
//   const imageUrl =
//     imageFormats.large?.url ||
//     imageFormats.medium?.url ||
//     imageFormats.small?.url ||
//     title_background?.data?.attributes?.url ||
//     "";

//   // Process body content
//   const contentElements = [];

//   if (Array.isArray(body)) {
//     body.forEach((block) => {
//       if (block.type === "paragraph") {
//         contentElements.push({
//           type: "paragraph",
//           text: block.children.map((child) => child.text).join(" "),
//         });
//       } else if (block.type === "heading") {
//         contentElements.push({
//           type: "heading",
//           level: block.level,
//           text: block.children.map((child) => child.text).join(" "),
//         });
//       } else if (block.type === "list") {
//         contentElements.push({
//           type: "list",
//           format: block.format,
//           items: block.children.map((item) =>
//             item.children.map((child) => child.text).join(" ")
//           ),
//         });

//       }
      
//     });
//   }

//   const headingStyles =
//     "w-auto  h-[34px] mb-[16px] font-primary text-[24px] sm:text-[32px] font-semibold leading-tight text-left text-[#002A57]";
//   const paragraphStyles =
//     "text-[16px] sm:text-[18px] font-secondary font-normal text-[#433E3F] text-justify mb-1";

//   return (
//     <>
//       <div>
//         {/* Header Section */}
//         <div className="relative  w-auto  h-[300px] sm:h-[541px]">
//           <img
//             src={imageUrl}
//             alt={title}
//             className="w-full h-full object-cover"
//           />

//           <div className="absolute inset-0 bg-[#0A0A0BB2]"></div>
//           {/* Title */}
//           <div className="absolute top-[50px] sm:top-[137px] left-[16px] sm:left-[120px] right-[16px] sm:right-auto w-auto sm:w-[651px] h-auto sm:h-[335px] flex flex-col items-start pb-[102px]">
//             <div className="max-w-[1440px] mx-auto">
//               <h1 className="text-[32px]  sm:text-[64px] font-primary font-bold leading-tight text-[#FAFAFA]">
//                 {title}
//               </h1>

//               <p className="mt-4 text-[16px] sm:text-[18px] font-primary font-normal leading-relaxed text-white max-w-full sm:max-w-[577px]">
//                 {description}
//               </p>

//               {/* Buttons */}
//               <div className="flex mt-6 gap-4 flex-wrap">
//                 <div
//                   className="w-[120px] sm:w-[152px] h-[40px] sm:h-[48px] bg-custom-blue rounded-[30px] flex items-center justify-center cursor-pointer"
//                   onClick={handleDonateClick}
//                 >
//                   <span className="font-primary text-[13px] font-medium leading-[18px] text-white text-center">
//                     Invest
//                   </span>
//                 </div>
//                 <div
//                   className="w-[120px] sm:w-[152px] h-[40px] sm:h-[48px] border border-white rounded-[30px] flex items-center justify-center cursor-pointer"
//                   onClick={handleContactUsClick}
//                 >
//                   <span className="font-primary text-[13px] font-medium leading-[18px] text-white text-center">
//                     Contact Us
//                   </span>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>

//         {/* Content Section */}
//         <div className="w-full max-w-[808px] mx-auto mt-12 flex flex-col gap-2 opacity-100 mb-16 px-4">
//           {contentElements.map((element, index) => {
//             if (element.type === "heading") {
//               const HeadingTag = `h${element.level}`;
//               return (
//                 <HeadingTag key={index} className={headingStyles}>
//                   {element.text}
//                 </HeadingTag>
//               );
//             } else if (element.type === "paragraph") {
//               return (
//                 <p key={index} className={paragraphStyles}>
//                   {element.text}
//                 </p>
//               );
//             } else if (element.type === "list") {
//               const ListTag = element.format === "unordered" ? "ul" : "ol";
//               return (
//                 <ListTag key={index} className="list-disc pl-5">
//                   {element.items.map((itemText, itemIndex) => (
//                     <li key={itemIndex} className={paragraphStyles}>
//                       {itemText}
//                     </li>
//                   ))}
//                 </ListTag>
//               );
//             } else {
//               return null;
//             }
//           })}
//         </div>
//       </div>
//       <Newsletter />
//     </>
//   );
// };

// export default TaskforceDetails;


import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Newsletter from "./newsLetter";

const TaskforceDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [taskforce, setTaskforce] = useState(null);
  const [loading, setLoading] = useState(true);

  const handleContactUsClick = () => {
    navigate("/contact");
  };
  const handleDonateClick = () => {
    navigate("/donate");
  };

  useEffect(() => {
    const fetchTaskforce = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/api/taskforces/${id}?populate=*`,
          {
            headers: {
              Authorization: `Bearer ${process.env.REACT_APP_CMS_TOKEN}`,
            },
          }
        );
        const data = await response.json();
        setTaskforce(data.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching taskforce data: ", error);
        setLoading(false);
      }
    };

    fetchTaskforce();
  }, [id]);

  if (loading) {
    return <div className="text-center h-[90vh] flex items-center justify-center w-full">Loading...</div>;
  }

  if (!taskforce) {
    return <div className="text-center h-[90vh] flex items-center justify-center w-full">Taskforce not found.</div>;
  }

  const { title, description, body, title_background } = taskforce.attributes;
  const imageFormats = title_background?.data?.attributes?.formats || {};
  const imageUrl =
    imageFormats.large?.url ||
    imageFormats.medium?.url ||
    imageFormats.small?.url ||
    title_background?.data?.attributes?.url ||
    "";

  const headingStyles =
    "w-auto h-[34px] mb-[16px] font-primary text-[24px] sm:text-[32px] font-semibold leading-tight text-left text-[#002A57]";
  const paragraphStyles =
    "text-[16px] sm:text-[18px] font-secondary font-normal text-[#433E3F] text-justify mb-1";

  // Recursive rendering function
  const renderContent = (nodes) => {
    return nodes.map((node, index) => {
      if (node.type === "text") {
        return node.text;
      } else if (node.type === "link") {
        return (
          <a
            key={index}
            href={node.url}
            className="text-blue-600 underline"
            target="_blank"
            rel="noopener noreferrer"
          >
            {renderContent(node.children)}
          </a>
        );
      } else if (node.type === "paragraph") {
        return (
          <p key={index} className={paragraphStyles}>
            {renderContent(node.children)}
          </p>
        );
      } else if (node.type === "heading") {
        const HeadingTag = `h${node.level}`;
        return (
          <HeadingTag key={index} className={headingStyles}>
            {renderContent(node.children)}
          </HeadingTag>
        );
      } else if (node.type === "list") {
        const ListTag = node.format === "unordered" ? "ul" : "ol";
        return (
          <ListTag key={index} className="list-disc pl-5">
            {node.children.map((item, itemIndex) =>
              renderContent([item])
            )}
          </ListTag>
        );
      } else if (node.type === "list-item") {
        return (
          <li key={index} className={paragraphStyles}>
            {renderContent(node.children)}
          </li>
        );
      } else {
        // Handle other node types or return null
        return null;
      }
    });
  };

  return (
    <>
      <div>
        {/* Header Section */}
        <div className="relative w-auto h-[300px] sm:h-[541px]">
          <img
            src={imageUrl}
            alt={title}
            className="w-full h-full object-cover"
          />

          <div className="absolute inset-0 bg-[#0A0A0BB2]"></div>
          {/* Title */}
          <div className="absolute top-[50px] sm:top-[137px] left-[16px] sm:left-[120px] right-[16px] sm:right-auto w-auto sm:w-[651px] h-auto sm:h-[335px] flex flex-col items-start pb-[102px]">
            <div className="max-w-[1440px] mx-auto">
              <h1 className="text-[32px] sm:text-[64px] font-primary font-bold leading-tight text-[#FAFAFA]">
                {title}
              </h1>

              <p className="mt-4 text-[16px] sm:text-[18px] font-primary font-normal leading-relaxed text-white max-w-full sm:max-w-[577px]">
                {description}
              </p>

              {/* Buttons */}
              <div className="flex mt-6 gap-4 flex-wrap">
                <div
                  className="w-[120px] sm:w-[152px] h-[40px] sm:h-[48px] bg-custom-blue rounded-[30px] flex items-center justify-center cursor-pointer"
                  onClick={handleDonateClick}
                >
                  <span className="font-primary text-[13px] font-medium leading-[18px] text-white text-center">
                    Invest
                  </span>
                </div>
                <div
                  className="w-[120px] sm:w-[152px] h-[40px] sm:h-[48px] border border-white rounded-[30px] flex items-center justify-center cursor-pointer"
                  onClick={handleContactUsClick}
                >
                  <span className="font-primary text-[13px] font-medium leading-[18px] text-white text-center">
                    Contact Us
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Content Section */}
        <div className="w-full max-w-[808px] mx-auto mt-12 flex flex-col gap-2 opacity-100 mb-16 px-4">
          {renderContent(body)}
        </div>
      </div>
      <Newsletter />
    </>
  );
};

export default TaskforceDetails;
