export const chargeUser = async (data) => {
    try {
        // Create payment data object
        const paymentData = {
            card_owner_name: data.cardOwnerName,
            card_number: data.cardNumber,
            card_exp_month: data.cardExpMonth,
            card_exp_year: data.cardExpYear,
            card_csc: data.securityCode,
            card_zip: data.billingZip,
        };


        const tokenResponse = await new Promise((resolve, reject) => {
            window.paymentspring.generateToken(
                process.env.REACT_APP_PAYMENTSPRING_PUBLIC_KEY,
                paymentData,
                function (response) {
                    if (response.errors) {
                        reject({
                            success: false,
                            error: response.errors.map((error) => error?.message).join(", "),
                        });
                    } else {
                        resolve(response);
                    }
                }
            );
        });

        // Make API request to charge the user
        const chargeResponse = await fetch(`${process.env.REACT_APP_BASE_URL}/api/charge-user`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${process.env.REACT_APP_CHARGE_API_TOKEN}`,
            },
            body: JSON.stringify({
                email: data.email,
                id: tokenResponse.id,
                amount: data.amount * 100,
                cvv: data.securityCode,
                zip: data.zipCode,
            }),
        });

        if (!chargeResponse.ok) {
            throw new Error("Failed to charge customer.");
        }

        const chargeData = await chargeResponse.json();

        return {
            success: true,
            data: chargeData,
        };
    } catch (err) {
        return {
            success: false,
            error: err.message || err.error || "An error occurred while processing your payment",
        };
    }
};



const getOrdinalSuffix = (day) => {
    if (day > 3 && day < 21) return `${day}th`; // Covers 4th-20th
    const suffixes = ["st", "nd", "rd"];
    const mod = day % 10;
    return `${day}${suffixes[mod - 1] || "th"}`;
};

export const formattedDay = getOrdinalSuffix(new Date().getDate());