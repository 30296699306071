import React, { useEffect, useState } from "react";
import backgroundImage from "../assets/background.png";
import Newsletter from "./newsLetter";

const Blog = () => {
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);

  // Fetch blogs from the Strapi API
  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/api/blogs?populate=*`,
          {
            headers: {
              Authorization: `Bearer ${process.env.REACT_APP_CMS_TOKEN}`,
            },
          }
        );

        if (response.ok) {
          const data = await response.json();
          setBlogs(data.data);
          console.log(data.data);
        } else {
          console.error("Error fetching data");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchBlogs();
  }, []);

  return (
    // <>
    //   <div>
    //     <div
    //       style={{
    //         width: "1440px",
    //         height: "338px",
    //         marginTop: "84px",
    //         padding: "75px 524px 94px 142px",
    //         background: `url(${backgroundImage}) no-repeat center center`,
    //         backgroundSize: "cover",
    //         backgroundPosition: "50% 1%",
    //       }}
    //     >
    //       <div
    //         style={{
    //           width: "774px",
    //           height: "224px",
    //           display: "flex",
    //           flexDirection: "column",
    //           opacity: 1,
    //         }}
    //       >
    //         <div
    //           style={{
    //             display: "flex",
    //             alignItems: "center",
    //             marginBottom: "16px",
    //           }}
    //         >
    //           <div
    //             style={{
    //               width: "72px",
    //               height: "0px",
    //               borderTop: "2px solid rgba(255, 165, 0, 1)",
    //               marginRight: "24px",
    //             }}
    //           ></div>
    //           <span
    //             style={{
    //               fontFamily: "Manrope",
    //               fontSize: "16px",
    //               fontWeight: 700,
    //               lineHeight: "21.86px",
    //               letterSpacing: "2px",
    //               color: "rgba(255, 165, 0, 1)",
    //             }}
    //           >
    //             BLOG
    //           </span>
    //         </div>
    //         <div
    //           style={{
    //             marginBottom: "32px",
    //           }}
    //         >
    //           <h1
    //             style={{
    //               fontFamily: "Manrope",
    //               fontSize: "64px",
    //               fontWeight: 700,
    //               lineHeight: "76.8px",
    //               color: "rgba(0, 42, 87, 1)",
    //               margin: 0,
    //             }}
    //           >
    //             News & Articles
    //           </h1>
    //         </div>
    //         <div>
    //           <p
    //             style={{
    //               width: "738px",
    //               fontFamily: "Manrope",
    //               fontSize: "18px",
    //               fontWeight: 400,
    //               lineHeight: "25.6px",
    //               color: "rgba(10, 10, 11, 1)",
    //               margin: 0,
    //             }}
    //           >
    //             Stay updated with the latest news, stories, and insights from
    //             the Faith Coalition for the Common Good. Explore our articles to
    //             learn about our initiatives, community impact, and upcoming
    //             events.
    //           </p>
    //         </div>
    //       </div>
    //     </div>

    //     {/* Blog Section */}
    //     <div
    //       style={{
    //         display: "flex",
    //         flexWrap: "wrap",
    //         gap: "30px",
    //         marginLeft: "120px",
    //         marginTop: "50px",
    //         marginBottom:"50px",
    //       }}
    //     >
    //       {loading ? (
    //         <p>Loading blogs...</p>
    //       ) : blogs.length > 0 ? (
    //         blogs.map((blog) => (
    //           <div
    //             key={blog.id}
    //             style={{
    //               width: "380px",
    //               height: "409px",
    //               borderRadius: "20px",
    //               border: "1px solid rgba(227, 227, 227, 1)",
    //               display: "flex",
    //               flexDirection: "column",
    //               overflow: "hidden",
    //               marginBottom:"20px"
    //             }}
    //           >
    //             {/* Image Section */}
    //             <div
    //               style={{
    //                 width: "100%",
    //                 height: "231px",
    //                 borderRadius: "20px 20px 0 0",
    //                 backgroundColor: blog.attributes.image_url
    //                   ? "transparent"
    //                   : "#f1f1f1",
    //                 backgroundImage: blog.attributes.image_url
    //                   ? `url(${blog.attributes.image_url})`
    //                   : "none",
    //                 backgroundSize: "cover",
    //                 backgroundPosition: "center",
    //               }}
    //             ></div>

    //             {/* Content Section */}
    //             <div
    //               style={{
    //                 padding: "20px",
    //                 display: "flex",
    //                 flexDirection: "column",
    //                 gap: "10px",
    //                 flexGrow: 1,
    //               }}
    //             >
    //               {/* Author and Date */}
    //               <div
    //                 style={{
    //                   display: "flex",
    //                   alignItems: "center",
    //                   fontFamily: "Open Sans",
    //                   fontSize: "13px",
    //                   color: "rgba(255, 165, 0, 1)",
    //                 }}
    //               >
    //                 <span>
    //                   By{" "}
    //                   {blog.attributes.author_name
    //                     ? blog.attributes.author_name
    //                     : "NA"}{" "}
    //                   |{" "}
    //                   {new Date(
    //                     blog.attributes.date_created
    //                   ).toLocaleDateString("en-US", {
    //                     month: "long",
    //                     day: "numeric",
    //                     year: "numeric",
    //                   })}
    //                 </span>
    //               </div>

    //               {/* Title */}
    //               <h2
    //                 style={{
    //                   fontFamily: "Manrope",
    //                   fontSize: "17px",
    //                   fontWeight: 600,
    //                   lineHeight: "24px",
    //                   color: "rgba(0, 42, 87, 1)",
    //                   margin: 0,
    //                   textTransform: "capitalize",
    //                 }}
    //               >
    //                 {blog.attributes.title || ""}
    //               </h2>
    //             </div>

    //             {/* Divider */}
    //             <div
    //               style={{
    //                 width: "332px",
    //                 marginLeft: "24px",
    //                 marginTop: "20px",
    //                 borderBottom: "1px solid rgba(227, 227, 227, 1)",
    //               }}
    //             ></div>

    //             {/* Read Article Section */}
    //             <div
    //               style={{
    //                 padding: "20px",
    //                 display: "flex",
    //                 justifyContent: "flex-end",
    //               }}
    //             >
    //               <a
    //                 href={`/blogs/${blog.id}`}
    //                 style={{
    //                   fontFamily: "Manrope",
    //                   fontSize: "14px",
    //                   color: "rgba(255, 165, 0, 1)",
    //                   textDecoration: "none",
    //                   display: "flex",
    //                   alignItems: "center",
    //                 }}
    //               >
    //                 <span>Read Article</span>
    //                 <svg
    //                   width="24"
    //                   height="24"
    //                   viewBox="24 24 24 24"
    //                   fill="none"
    //                   xmlns="http://www.w3.org/2000/svg"
    //                   style={{ marginLeft: "5px" }}
    //                 >
    //                   <path
    //                     fillRule="evenodd"
    //                     clipRule="evenodd"
    //                     d="M8.35731 16.1874C8.22969 16.4265 8.29933 16.7311 8.52942 16.8943C8.77868 17.071 9.12233 17.0151 9.29649 16.7695L14.8426 8.94793L15.6947 13.7986L15.7124 13.8712C15.7945 14.1297 16.0578 14.2965 16.3326 14.2527C16.6319 14.2046 16.8323 13.9232 16.7797 13.6247L15.6972 7.46322L15.6798 7.39143C15.6431 7.27488 15.5683 7.17479 15.4705 7.10541C15.412 7.064 15.3457 7.03386 15.2743 7.01713C15.0563 6.96598 14.8313 7.04981 14.7034 7.23017L8.39335 16.1291L8.35731 16.1874ZM8.44057 8.65675C8.49475 8.9555 8.78074 9.15829 9.08023 9.10853L12.4847 8.54748L12.5576 8.53044C12.8162 8.45109 12.9791 8.19162 12.9294 7.91777C12.9024 7.76839 12.8181 7.64354 12.7031 7.562C12.5869 7.4796 12.4406 7.44094 12.2898 7.46599L8.88569 8.02644L8.813 8.0436C8.55476 8.12335 8.39146 8.38329 8.44057 8.65675Z"
    //                     fill="#FFA500"
    //                   />
    //                 </svg>
    //               </a>
    //             </div>
    //           </div>
    //         ))
    //       ) : (
    //         <p>No blogs available.</p>
    //       )}
    //     </div>
    //   </div>
    //   <Newsletter />
    // </>
    <>
      <div>
        {/* Header Section */}
        <div
          className="w-full max-w-[1200px] mx-auto h-auto md:h-[338px] mt-[84px] px-4 pt-[75px] pb-[94px] bg-cover bg-no-repeat bg-center relative overflow-hidden"
          style={{
            backgroundImage: `url(${backgroundImage})`,
            backgroundPosition: "50% 1%",
          }}
        >
          <div className="w-full h-auto flex flex-col opacity-100 mx-auto px-4">
            <div className="flex items-center mb-[16px]">
              <div className="w-[72px] h-0 border-t-2 border-custom-orange mr-[24px]"></div>
              <span className="font-primary text-[16px] font-bold leading-[21.86px] tracking-[2px] text-custom-orange">
                BLOG
              </span>
            </div>
            <div className="mb-[32px]">
              <h1 className="font-primary text-[40px] md:text-[64px] font-bold leading-[48px] md:leading-[76.8px] text-custom-blue m-0">
                News & Articles
              </h1>
            </div>
            <div>
              <p className="w-full md:w-[738px] font-primary text-[16px] md:text-[18px] font-normal leading-[25.6px] text-[#0A0A0B] m-0">
                Stay updated with the latest news, stories, and insights from
                the Faith Coalition for the Common Good. Explore our articles to
                learn about our initiatives, community impact, and upcoming
                events.
              </p>
            </div>
          </div>
        </div>

        {/* Blog Section */}
        <div className="flex justify-center">
          <div className="flex flex-wrap gap-[30px] mt-[50px] mb-[50px] px-4 max-w-[1200px]">
            {loading ? (
              <div className="flex col-span-full h-[80vh] justify-center items-center text-custom-blue">
                <svg
                  className="animate-spin h-5 w-5 mr-3 border-t-2 border-b-2 border-custom-orange rounded-full"
                  viewBox="0 0 24 24"
                ></svg>
                Loading blogs...
              </div>
            ) : blogs.length > 0 ? (
              blogs.map((blog) => (
                <div
                  key={blog.id}
                  className="w-full md:w-[380px] h-auto rounded-[20px] border border-[#E3E3E3] flex flex-col overflow-hidden mb-[20px]"
                >
                  {/* Image Section */}
                  <div
                    className="w-full h-[231px] rounded-t-[20px]"
                    style={{
                      backgroundColor: blog.attributes.image?.data
                        ? "transparent"
                        : "#f1f1f1",
                      backgroundImage: blog.attributes.image?.data
                        ? `url(${blog.attributes.image.data.attributes.url})`
                        : "none",
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                    }}
                  ></div>

                  {/* Content Section */}
                  <div className="p-[20px] flex flex-col gap-[10px] flex-grow">
                    {/* Author and Date */}
                    <div className="flex items-center font-secondary text-[13px] text-custom-orange">
                      <span>
                        By{" "}
                        {blog.attributes.author_name
                          ? blog.attributes.author_name
                          : "Faith Coalition "}{" "}
                        |{" "}
                        {new Date(
                          blog.attributes.date_created
                        ).toLocaleDateString("en-US", {
                          month: "long",
                          day: "numeric",
                          year: "numeric",
                        })}
                      </span>
                    </div>

                    {/* Title */}
                    <h2 className="font-primary text-[17px] font-semibold leading-[24px] text-custom-blue m-0 capitalize">
                      {blog.attributes.title || ""}
                    </h2>
                  </div>

                  {/* Divider */}
                  <div className="w-[332px] ml-[24px] mt-[20px] border-b border-[#E3E3E3]"></div>

                  {/* Read Article Section */}
                  <div className="p-[20px] flex justify-end">
                    <a
                      href={
                        blog.attributes.external ? blog.attributes.externalLink : `/blogs/${blog.id}`
                      }
                      target={blog.attributes.external ? "_blank" : "_self"}
                      className="font-primary text-[14px] text-custom-orange no-underline flex items-center"
                    >
                      <span>Read Article</span>
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 25"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="ml-[5px]"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M8.35731 16.1874C8.22969 16.4265 8.29933 16.7311 8.52942 16.8943C8.77868 17.071 9.12233 17.0151 9.29649 16.7695L14.8426 8.94793L15.6947 13.7986L15.7124 13.8712C15.7945 14.1297 16.0578 14.2965 16.3326 14.2527C16.6319 14.2046 16.8323 13.9232 16.7797 13.6247L15.6972 7.46322L15.6798 7.39143C15.6431 7.27488 15.5683 7.17479 15.4705 7.10541C15.412 7.064 15.3457 7.03386 15.2743 7.01713C15.0563 6.96598 14.8313 7.04981 14.7034 7.23017L8.39335 16.1291L8.35731 16.1874ZM8.44057 8.65675C8.49475 8.9555 8.78074 9.15829 9.08023 9.10853L12.4847 8.54748L12.5576 8.53044C12.8162 8.45109 12.9791 8.19162 12.9294 7.91777C12.9024 7.76839 12.8181 7.64354 12.7031 7.562C12.5869 7.4796 12.4406 7.44094 12.2898 7.46599L8.88569 8.02644L8.813 8.0436C8.55476 8.12335 8.39146 8.38329 8.44057 8.65675Z"
                          fill="#FFA500"
                        />
                      </svg>
                    </a>
                  </div>
                </div>
              ))
            ) : (
              <p>No blogs available.</p>
            )}
          </div>
        </div>
      </div>
      <Newsletter />
    </>
  );
};

export default Blog;
