import React, { useState , useEffect } from "react";
import backgroundImage from "../assets/background.png";
import bMemberImage from "../assets/bMember.png";
import MemberForm from "./memberForm";
import Newsletter from "./newsLetter";

const BecomeMember = () => {

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  const [isFormVisible, setIsFormVisible] = useState(false);
  const [membershipType, setMembershipType] = useState("");

  

  const handleOpenForm = (type) => {
    setMembershipType(type);
    setIsFormVisible(true);
  };

  const handleCloseForm = () => {
    setIsFormVisible(false);
  };

  const svgIcon = (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ flexShrink: 0 }}
    >
      <g clipPath="url(#clip0_222_21535)">
        <path
          d="M12 24.5C18.6274 24.5 24 19.1274 24 12.5C24 5.87258 18.6274 0.5 12 0.5C5.37258 0.5 0 5.87258 0 12.5C0 19.1274 5.37258 24.5 12 24.5Z"
          fill="#FFA500"
        />
        <path d="M8 13.1923L10.4615 15.6538L16.6154 9.5" fill="#FFA500" />
        <path
          d="M8 13.1923L10.4615 15.6538L16.6154 9.5"
          stroke="#FAFAFA"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_222_21535">
          <rect width="24" height="25" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );

  return (
    //  <>
    //     <div
    //     style={{
    //       width: "100%", 
    //       height: "355px",
    //       top: "80px",
    //       padding: "80px 303.5px 127px 304.5px",
    //       position: "relative",
    //       overflow: "hidden",
    //       marginBottom: "80px",
    //       background: `url(${backgroundImage}) no-repeat center center`,
    //       backgroundSize: "cover",
    //       backgroundPosition: "50% 1%",
    //     }}
    //   >
    //     {/* <img
    //       src={backgroundImage}
    //       alt="Background"
    //       style={{
    //         position: "absolute",
    //         top: "0",
    //         left: "0",
    //         width: "100%",
    //         height: "100%",
    //         backgroundSize: "cover",
    //         backgroundPosition: "50% 1%",
    //       }}
    //     /> */}
    //     <div
    //       style={{
    //         width: "832px",
    //         height: "186px",
    //         margin: "0 auto",
    //         position: "relative",

    //         display: "flex",
    //         flexDirection: "column",
    //         alignItems: "center",
    //         justifyContent: "center",
    //       }}
    //     >
    //       <div
    //         style={{
    //           width: "832px",
    //           height: "77px",
    //           textAlign: "center",
    //           fontFamily: "Manrope",
    //           fontSize: "64px",
    //           fontWeight: "700",
    //           lineHeight: "76.8px",
    //           color: "rgba(0, 42, 87, 1)",
    //         }}
    //       >
    //         Become a Member
    //       </div>
    //       <div
    //         style={{
    //           width: "577px",
    //           height: "77px",
    //           textAlign: "center",
    //           fontFamily: "Manrope",
    //           fontSize: "18px",
    //           fontWeight: "400",
    //           lineHeight: "25.6px",
    //           color: "rgba(10, 10, 11, 1)",
    //           marginTop: "32px",
    //         }}
    //       >
    //         Join a passionate community committed to social justice and
    //         community empowerment. Be a part of our mission to drive meaningful
    //         change and make a lasting impact.
    //       </div>
    //     </div>
    //   </div>

    //   <div
    //     style={{
    //       width: "100%",
    //       height: "824px",
    //       background: "rgba(243, 249, 255, 1)",
    //       position: "relative",
    //       overflow: "hidden",
    //     }}
    //   >
    //     <div
    //       style={{
    //         width: "539px",
    //         height: "609px",
    //         top: "107px",
    //         left: "120px",
    //         borderRadius: "20px 20px 20px 20px",
    //         position: "absolute",
    //         overflow: "hidden",
    //       }}
    //     >
    //       <img
    //         src={bMemberImage}
    //         alt="Member"
    //         style={{
    //           width: "100%",
    //           height: "100%",
    //           objectFit: "cover",
    //           borderRadius: "20px 20px 20px 20px",
    //         }}
    //       />
    //     </div>

    //     <div
    //       style={{
    //         width: "585px",
    //         height: "532px",
    //         top: "146px",
    //         left: "706px",
    //         gap: "32px",
    //         position: "absolute",
    //       }}
    //     >
    //       <div
    //         style={{
    //           width: "534px",
    //           height: "100px",
    //           fontFamily: "Manrope",
    //           fontSize: "42px",
    //           fontWeight: "700",
    //           lineHeight: "50.4px",
    //           color: "rgba(0, 42, 87, 1)",
    //           textAlign: "left",
    //           marginBottom: "32px",
    //         }}
    //       >
    //         Individual / Family Membership
    //       </div>

    //       <div
    //         style={{
    //           width: "585px",
    //           height: "116px",
    //           fontFamily: "Open Sans",
    //           fontSize: "18px",
    //           fontWeight: "400",
    //           lineHeight: "28.8px",
    //           color: "rgba(67, 62, 63, 1)",
    //           textAlign: "left",
    //           marginBottom: "32px",
    //         }}
    //       >
    //         We invite all who share the values of racial and economic equity to
    //         join us. Congregations, organizations, and individuals can become
    //         members to advance social justice in Springfield and beyond. Below
    //         is a role of FCCG members.
    //       </div>

    //       <div style={{ marginBottom: "32px" }}>
    //         <div
    //           style={{
    //             width: "481.93px",
    //             fontFamily: "Manrope",
    //             fontSize: "18px",
    //             fontWeight: "400",
    //             lineHeight: "28px",
    //             color: "rgba(67, 62, 63, 1)",
    //             textAlign: "left",
    //             marginBottom: "16px",
    //             display: "flex",
    //             alignItems: "center",
    //             gap: "16px",
    //           }}
    //         >
    //           {svgIcon} Pay annual membership dues
    //         </div>
    //         <div
    //           style={{
    //             width: "584px",
    //             fontFamily: "Manrope",
    //             fontSize: "18px",
    //             fontWeight: "400",
    //             lineHeight: "28px",
    //             color: "rgba(67, 62, 63, 1)",
    //             textAlign: "left",
    //             marginBottom: "16px",
    //             display: "flex",
    //             alignItems: "center",
    //             gap: "16px",
    //           }}
    //         >
    //           {svgIcon} Participate in local/national leadership trainings, as
    //           appropriate.
    //         </div>
    //         <div
    //           style={{
    //             width: "544px",
    //             fontFamily: "Manrope",
    //             fontSize: "18px",
    //             fontWeight: "400",
    //             lineHeight: "28px",
    //             color: "rgba(67, 62, 63, 1)",
    //             textAlign: "left",
    //             marginBottom: "16px",
    //             display: "flex",
    //             alignItems: "flex-start",
    //             gap: "16px",
    //           }}
    //         >
    //           <div style={{ marginTop: "4px" }}>{svgIcon}</div>
    //           <div>
    //             Support the ongoing work of FCCG through individual leader’s
    //             donations, and ongoing fundraising and recruitment efforts
    //           </div>
    //         </div>

    //         <div
    //           style={{
    //             width: "441.93px",
    //             fontFamily: "Manrope",
    //             fontSize: "18px",
    //             fontWeight: "400",
    //             lineHeight: "28px",
    //             color: "rgba(67, 62, 63, 1)",
    //             textAlign: "left",
    //             display: "flex",
    //             alignItems: "center",
    //             gap: "16px",
    //           }}
    //         >
    //           {svgIcon} Participate in FCCG issue and action work
    //         </div>
    //       </div>

    //       <button
    //         style={{
    //           width: "171px",
    //           height: "48px",
    //           padding: "16px",
    //           backgroundColor: "rgba(0, 42, 87, 1)",
    //           borderRadius: "30px 30px 30px 30px",
    //           color: "white",
    //           fontFamily: "Manrope",
    //           fontSize: "14px",
    //           fontWeight: "500",
    //           lineHeight: "18px",
    //           textAlign: "center",
    //           transition: "all 0.3s ease-in-out",
    //         }}
    //         onClick={() => handleOpenForm("individual")}
    //       >
    //         Become a member
    //       </button>
    //     </div>
    //   </div>

    //   <div
    //     style={{
    //       width: "100%",
    //       height: "824px",
    //       background: "rgba(255, 242, 219, 1)",
    //       position: "relative",
    //       overflow: "hidden",
    //       // Adding margin to ensure spacing from previous section
    //     }}
    //   >
    //     <div
    //       style={{
    //         width: "539px",
    //         height: "609px",
    //         top: "107px",
    //         left: "752px",
    //         borderRadius: "20px 20px 20px 20px",
    //         position: "absolute",
    //         overflow: "hidden",
    //       }}
    //     >
    //       <img
    //         src={bMemberImage}
    //         alt="Member"
    //         style={{
    //           width: "100%",
    //           height: "100%",
    //           objectFit: "cover",
    //           borderRadius: "20px 20px 20px 20px",
    //         }}
    //       />
    //     </div>

    //     <div
    //       style={{
    //         width: "585px",
    //         top: "88px",
    //         left: "120px",
    //         position: "absolute",
    //         display: "flex",
    //         flexDirection: "column",
    //         gap: "32px",
    //       }}
    //     >
    //       <div
    //         style={{
    //           fontFamily: "Manrope",
    //           fontSize: "42px",
    //           fontWeight: "700",
    //           lineHeight: "50.4px",
    //           color: "rgba(0, 42, 87, 1)",
    //           textAlign: "left",
    //         }}
    //       >
    //         Organizational Membership
    //       </div>

    //       <div
    //         style={{
    //           fontFamily: "Open Sans",
    //           fontSize: "18px",
    //           fontWeight: "400",
    //           lineHeight: "28.8px",
    //           color: "rgba(67, 62, 63, 1)",
    //           textAlign: "left",
    //         }}
    //       >
    //         We invite all who share the values of racial and economic equity to
    //         join us. Congregations, organizations, and individuals can become
    //         members to advance social justice in Springfield and beyond. Below
    //         is a role of FCCG members.
    //       </div>

    //       <div>
    //         <div
    //           style={{
    //             width: "441.93px",
    //             fontFamily: "Manrope",
    //             fontSize: "18px",
    //             fontWeight: "400",
    //             lineHeight: "28px",
    //             color: "rgba(67, 62, 63, 1)",
    //             textAlign: "left",
    //             marginBottom: "16px",
    //             display: "flex",
    //             alignItems: "center",
    //             gap: "16px",
    //           }}
    //         >
    //           {svgIcon} Pay annual membership dues
    //         </div>
    //         <div
    //           style={{
    //             width: "544px",
    //             fontFamily: "Manrope",
    //             fontSize: "18px",
    //             fontWeight: "400",
    //             lineHeight: "28px",
    //             color: "rgba(67, 62, 63, 1)",
    //             textAlign: "left",
    //             marginBottom: "16px",
    //             display: "flex",
    //             alignItems: "flex-start",
    //             gap: "16px",
    //           }}
    //         >
    //           <div style={{ marginTop: "4px" }}>{svgIcon}</div>
    //           Designate one leader from their congregation/organization to serve
    //           on the FCCG Board of Directors
    //         </div>
    //         <div
    //           style={{
    //             width: "544px",
    //             fontFamily: "Manrope",
    //             fontSize: "18px",
    //             fontWeight: "400",
    //             lineHeight: "28px",
    //             color: "rgba(67, 62, 63, 1)",
    //             textAlign: "left",
    //             marginBottom: "16px",
    //             display: "flex",
    //             alignItems: "flex-start",
    //             gap: "16px",
    //           }}
    //         >
    //           <div style={{ marginTop: "4px" }}>{svgIcon}</div>
    //           Support the ongoing work of FCCG through individual leader’s
    //           donations, and ongoing fundraising and recruitment efforts
    //         </div>

    //         <div
    //           style={{
    //             width: "441.93px",
    //             fontFamily: "Manrope",
    //             fontSize: "18px",
    //             fontWeight: "400",
    //             lineHeight: "28px",
    //             color: "rgba(67, 62, 63, 1)",
    //             textAlign: "left",
    //             display: "flex",
    //             alignItems: "center",
    //             gap: "16px",
    //           }}
    //         >
    //           {svgIcon} Participate in FCCG issue and action work
    //         </div>
    //         <div
    //           style={{
    //             width: "465px",
    //             fontFamily: "Manrope",
    //             fontSize: "18px",
    //             fontWeight: "400",
    //             lineHeight: "28px",
    //             color: "rgba(67, 62, 63, 1)",
    //             textAlign: "left",
    //             display: "flex",
    //             alignItems: "center",
    //             gap: "16px",
    //           }}
    //         >
    //           {svgIcon} Have active congregational/organizational core teams
    //         </div>
    //         <div
    //           style={{
    //             width: "487px",
    //             fontFamily: "Manrope",
    //             fontSize: "18px",
    //             fontWeight: "400",
    //             lineHeight: "28px",
    //             color: "rgba(67, 62, 63, 1)",
    //             textAlign: "left",
    //             display: "flex",
    //             alignItems: "center",
    //             gap: "16px",
    //           }}
    //         >
    //           {svgIcon} Send leaders to local and national leadership trainings
    //         </div>
    //       </div>

    //       <button
    //         style={{
    //           width: "171px",
    //           height: "48px",
    //           backgroundColor: "rgba(0, 42, 87, 1)",
    //           borderRadius: "30px 30px 30px 30px",
    //           color: "white",
    //           fontFamily: "Manrope",
    //           fontSize: "14px",
    //           fontWeight: "500",
    //           lineHeight: "18px",
    //           textAlign: "center",
    //           transition: "all 0.3s ease-in-out",
    //         }}
    //         onClick={() => handleOpenForm("organizational")}
    //       >
    //         Become a member
    //       </button>
    //     </div>
    //     <MemberForm isVisible={isFormVisible} onClose={handleCloseForm} type={membershipType} />
    //   </div>
    //   <Newsletter/>
    // </>
    <>
    {/* Header Section */}
    <div
      className="w-full h-auto relative overflow-hidden mb-[0px] bg-cover bg-no-repeat bg-center"
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundPosition: "50% 1%",
      }}
    >
      <div className="max-w-[832px] mx-auto pt-[120px] pb-[80px] md:py-[127px] px-4 flex flex-col items-center justify-center">
        <h1 className="w-full text-center font-primary text-[40px] md:text-[64px] font-bold leading-tight text-custom-blue">
          Become a Member
        </h1>
        <p className="w-full max-w-[577px] text-center font-primary text-[16px] md:text-[18px] font-normal leading-relaxed text-[#0A0A0B] mt-[32px]">
          Join a passionate community committed to social justice and community
          empowerment. Be a part of our mission to drive meaningful change and
          make a lasting impact.
        </p>
      </div>
    </div>

    {/* Individual / Family Membership Section */}
    <div className="w-full bg-custom-lightblue relative overflow-hidden">
      <div className="max-w-[1200px] mx-auto flex flex-col lg:flex-row items-center lg:items-start py-10">
        {/* Image */}
        <div className="w-full lg:w-1/2 flex justify-center lg:justify-start">
          <div className="w-[400px] md:w-[539px] h-[338px] md:h-[609px] rounded-lg overflow-hidden">
            <img
              src={bMemberImage}
              alt="Member"
              className="w-full h-full object-cover rounded-lg"
            />
          </div>
        </div>

        {/* Text Content */}
        <div className="w-full lg:w-1/2 mt-8 lg:mt-0 px-4 lg:px-0 flex flex-col gap-[32px]">
          <h2 className="font-primary text-[32px] md:text-[42px] font-bold leading-tight text-custom-blue">
            Individual / Family Membership
          </h2>
          <p className="font-secondary text-[16px] md:text-[18px] font-normal leading-relaxed text-custom-lightgray">
            We invite all who share the values of racial and economic equity to join
            us. Congregations, organizations, and individuals can become members to
            advance social justice in Springfield and beyond. Below is a role of
            FCCG members.
          </p>

          <div className="space-y-[16px]">
            <div className="flex items-start gap-[16px]">
              {svgIcon}
              <p className="font-primary text-[16px] md:text-[18px] font-normal leading-relaxed text-custom-lightgray">
                Pay annual membership dues
              </p>
            </div>
            <div className="flex items-start gap-[16px]">
              {svgIcon}
              <p className="font-primary text-[16px] md:text-[18px] font-normal leading-relaxed text-custom-lightgray">
                Participate in local/national leadership trainings, as appropriate.
              </p>
            </div>
            <div className="flex items-start gap-[16px]">
              {svgIcon}
              <p className="font-primary text-[16px] md:text-[18px] font-normal leading-relaxed text-custom-lightgray">
                Support the ongoing work of FCCG through individual leader’s donations,
                and ongoing fundraising and recruitment efforts
              </p>
            </div>
            <div className="flex items-start gap-[16px]">
              {svgIcon}
              <p className="font-primary text-[16px] md:text-[18px] font-normal leading-relaxed text-custom-lightgray">
                Participate in FCCG issue and action work
              </p>
            </div>
          </div>

          <button
            className="w-[171px] h-[48px] bg-custom-blue rounded-xl text-white font-primary text-[14px] font-medium leading-[18px] text-center transition-all duration-300 ease-in-out mt-[32px]"
            onClick={() => handleOpenForm("individual")}
          >
            Become a member
          </button>
        </div>
      </div>
    </div>

    {/* Organizational Membership Section */}
    <div className="w-full bg-[#FFF2DB] relative overflow-hidden">
      <div className="max-w-[1200px] mx-auto flex flex-col lg:flex-row-reverse items-center lg:items-start py-10">
        {/* Image */}
        <div className="w-full lg:w-1/2 flex justify-center lg:justify-end">
          <div className="w-[400px] md:w-[539px] h-[338px] md:h-[609px] rounded-lg overflow-hidden">
            <img
              src={bMemberImage}
              alt="Member"
              className="w-full h-full object-cover rounded-lg"
            />
          </div>
        </div>

        {/* Text Content */}
        <div className="w-full lg:w-1/2 mt-8 lg:mt-0 px-4 lg:px-0 flex flex-col gap-[32px]">
          <h2 className="font-primary text-[32px] md:text-[42px] font-bold leading-tight text-custom-blue">
            Organizational Membership
          </h2>
          <p className="font-secondary text-[16px] md:text-[18px] font-normal leading-relaxed text-custom-lightgray">
            We invite all who share the values of racial and economic equity to join
            us. Congregations, organizations, and individuals can become members to
            advance social justice in Springfield and beyond. Below is a role of
            FCCG members.
          </p>

          <div className="space-y-[16px]">
            <div className="flex items-start gap-[16px]">
              {svgIcon}
              <p className="font-primary text-[16px] md:text-[18px] font-normal leading-relaxed text-custom-lightgray">
                Pay annual membership dues
              </p>
            </div>
            <div className="flex items-start gap-[16px]">
              {svgIcon}
              <p className="font-primary text-[16px] md:text-[18px] font-normal leading-relaxed text-custom-lightgray">
                Designate one leader from their congregation/organization to serve on
                the FCCG Board of Directors
              </p>
            </div>
            <div className="flex items-start gap-[16px]">
              {svgIcon}
              <p className="font-primary text-[16px] md:text-[18px] font-normal leading-relaxed text-custom-lightgray">
                Support the ongoing work of FCCG through individual leader’s donations,
                and ongoing fundraising and recruitment efforts
              </p>
            </div>
            <div className="flex items-start gap-[16px]">
              {svgIcon}
              <p className="font-primary text-[16px] md:text-[18px] font-normal leading-relaxed text-custom-lightgray">
                Participate in FCCG issue and action work
              </p>
            </div>
            <div className="flex items-start gap-[16px]">
              {svgIcon}
              <p className="font-primary text-[16px] md:text-[18px] font-normal leading-relaxed text-custom-lightgray">
                Have active congregational/organizational core teams
              </p>
            </div>
            <div className="flex items-start gap-[16px]">
              {svgIcon}
              <p className="font-primary text-[16px] md:text-[18px] font-normal leading-relaxed text-custom-lightgray">
                Send leaders to local and national leadership trainings
              </p>
            </div>
          </div>

          <button
            className="w-[171px] h-[48px] bg-custom-blue rounded-xl text-white font-primary text-[14px] font-medium leading-[18px] text-center transition-all duration-300 ease-in-out mt-[32px]"
            onClick={() => handleOpenForm("organizational")}
          >
            Become a member
          </button>
        </div>
      </div>
      <MemberForm
        isVisible={isFormVisible}
        onClose={handleCloseForm}
        type={membershipType}
      />
    </div>

    <Newsletter />
  </>
  );
};

export default BecomeMember;
