// import React, { useState, useEffect } from "react";
// import janetImage from "../assets/janet.png"; 
// import robertImage from "../assets/robert.png";  

// const testimonials = [
//   {
//     image: janetImage,
//     name: "Janet Henry",
//     location: "Springfield, IL",
//     text: "Faith Coalition's programs have empowered me to take action and be a part of meaningful change. It's amazing to see our efforts lead to tangible improvements in our community.",
//   },
//   {
//     image: robertImage,
//     name: "Robert Johnson",
//     location: "Springfield, IL",
//     text: "Volunteering with Faith Coalition has been transformative. The community's dedication to social justice inspires me, and together, we're making a real impact.",
//   },
//   {
//     image: janetImage,
//     name: "Michael Doe",
//     location: "Chicago, IL",
//     text: "I have seen firsthand the positive impact Faith Coalition has on the community. Their dedication to making a difference is truly inspiring.",
//   },
//   {
//     image: robertImage,
//     name: "Anna Smith",
//     location: "New York, NY",
//     text: "The support and resources provided by Faith Coalition have been invaluable. I am grateful to be a part of this amazing community.",
//   },
// ];

// const TestimonialsSection = () => {
//   const [currentSet, setCurrentSet] = useState(0);
//   const [transitioning, setTransitioning] = useState(false);
//   const [direction, setDirection] = useState(""); 
//   const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

//   useEffect(() => {
//     const handleResize = () => setIsMobile(window.innerWidth < 768);
//     window.addEventListener("resize", handleResize);
//     return () => window.removeEventListener("resize", handleResize);
//   }, []);

//   const testimonialsPerPage = isMobile ? 1 : 2; 
//   const totalSets = Math.ceil(testimonials.length / testimonialsPerPage);

//   const handleNext = () => {
//     if (!transitioning) {
//       setDirection("next");
//       setTransitioning(true);
//       setTimeout(() => {
//         setCurrentSet((prevSet) => (prevSet + 1) % totalSets);
//         setTransitioning(false);
//       }, 500);
//     }
//   };

//   const handlePrev = () => {
//     if (!transitioning) {
//       setDirection("prev");
//       setTransitioning(true);
//       setTimeout(() => {
//         setCurrentSet((prevSet) => (prevSet - 1 + totalSets) % totalSets);
//         setTransitioning(false);
//       }, 500);
//     }
//   };

//   return (
//     <div
//       style={{
//         width: "100%",
//         height: "auto",
//         padding: "50px 20px",
//         gap: "32px",
//         opacity: 1,
//         overflow: "hidden",
//         display: "flex",
//         justifyContent: "center",
//       }}
//     >
//       <style>
//         {`
//           .slide-in-right {
//             animation: slideInRight 0.5s forwards;
//           }
//           .slide-out-left {
//             animation: slideOutLeft 0.5s forwards;
//           }
//           .slide-in-left {
//             animation: slideInLeft 0.5s forwards;
//           }
//           .slide-out-right {
//             animation: slideOutRight 0.5s forwards;
//           }
//           @keyframes slideInRight {
//             from {
//               transform: translateX(100%);
//             }
//             to {
//               transform: translateX(0);
//             }
//           }
//           @keyframes slideOutLeft {
//             from {
//               transform: translateX(0);
//             }
//             to {
//               transform: translateX(-100%);
//             }
//           }
//           @keyframes slideInLeft {
//             from {
//               transform: translateX(-100%);
//             }
//             to {
//               transform: translateX(0);
//             }
//           }
//           @keyframes slideOutRight {
//             from {
//               transform: translateX(0);
//             }
//             to {
//               transform: translateX(100%);
//             }
//           }
//           .testimonial-container {
//             display: flex;
//             transition: transform 0.5s ease-in-out;
//           }
//           @media (max-width: 768px) {
//             .testimonial-card {
//               width: 357px !important;
//               height: auto !important;
//               padding: 24px !important;
//               gap: 0px !important;
//               opacity: 1 !important;
//               box-sizing: border-box;
//               flex: 0 0 auto;
//               margin-right: 34px !important; /* Added margin for spacing */
//               overflow: hidden !important; /* Ensure content is visible */
//               background: rgba(246, 246, 249, 1); /* Grey background */
//             }
//             .testimonial-card:last-child {
//               margin-right: 0 !important; /* Remove margin for the last card */
//               margin-left:  0!important
              
//             }
//             .testimonial-card > div {
//               width: 100% !important;
//               gap: 24px !important;
//               opacity: 1 !important;
//             }
//             .arrow {
//               display: none !important;
//             }
//             .testimonial-container {
//               overflow-x: scroll;
//               -webkit-overflow-scrolling: touch; /* Enable momentum scrolling on iOS */
//               scroll-snap-type: x mandatory; /* Snap scrolling */
//             }
//             .testimonial-card {
//               scroll-snap-align: start; /* Snap each testimonial card */
//             }
//           }
//           @media (min-width: 769px) {
//             .testimonial-card {
//               width: 585px !important;
//               height: auto !important;
//               gap: 0px !important;
//               opacity: 1 !important;
//               box-sizing: border-box;
//               flex: 0 0 auto;
//               overflow: hidden !important; /* Ensure content is visible */
//               background: rgba(246, 246, 249, 1); /* Grey background */
//             }
//             .testimonial-card > div {
//               width: 100% !important;
//               gap: 24px !important;
//               opacity: 1 !important;
//             }
//           }
//           .testimonial-location {
//             margin-bottom: 8px;
//           }
//           .testimonial-text {
//             margin-top: 8px;
//             white-space: normal;
//             overflow: visible;
//             flex-grow: 1;
//           }
//         `}
//       </style>
//       <div
//         style={{
//           width: "100%",
//           maxWidth: isMobile ? "100%" : "1200px",
//           gap: "32px",
//           display: "flex",
//           flexDirection: "column",
//         }}
//       >
//         <div style={{ display: "flex", flexDirection: "column", gap: "24px" }}>
//           <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
//             <div
//               style={{
//                 width: "72px",
//                 height: "2px",
//                 background: "rgba(255, 165, 0, 1)",
//               }}
//             ></div>
//             <div
//               style={{
//                 fontFamily: "Manrope",
//                 fontSize: "16px",
//                 fontWeight: "700",
//                 lineHeight: "21.86px",
//                 letterSpacing: "2px",
//                 textAlign: "left",
//                 color: "rgba(255, 165, 0, 1)",
//               }}
//             >
//               TESTIMONIALS
//             </div>
//           </div>
//           <div
//             style={{
//               display: "flex",
//               alignItems: "center",
//               justifyContent: "space-between",
//             }}
//           >
//             <div
//               style={{
//                 fontFamily: "Manrope",
//                 fontSize: "32px",
//                 fontWeight: "700",
//                 lineHeight: "38.4px",
//                 textAlign: "left",
//                 color: "rgba(0, 42, 87, 1)",
//               }}
//             >
//               Feedback from community members
//             </div>
//             <div className="arrow" style={{ display: "flex", gap: "8px" }}>
//               <svg
//                 onClick={handlePrev}
//                 width="36"
//                 height="36"
//                 viewBox="0 0 36 36"
//                 fill="none"
//                 xmlns="http://www.w3.org/2000/svg"
//                 style={{ cursor: "pointer" }}
//               >
//                 <rect width="36" height="36" rx="18" fill="#002A57" />
//                 <g clipPath="url(#clip0_704_3422)">
//                   <g clipPath="url(#clip1_704_3422)">
//                     <path
//                       d="M15.9744 12.9419L10.916 18.0002L15.9744 23.0586"
//                       stroke="white"
//                       strokeMiterlimit="10"
//                       strokeLinecap="round"
//                       strokeLinejoin="round"
//                     />
//                     <path
//                       d="M25.0836 18H11.0586"
//                       stroke="white"
//                       strokeMiterlimit="10"
//                       strokeLinecap="round"
//                       strokeLinejoin="round"
//                     />
//                   </g>
//                 </g>
//                 <defs>
//                   <clipPath id="clip0_704_3422">
//                     <rect
//                       width="20"
//                       height="20"
//                       fill="white"
//                       transform="translate(8 8)"
//                     />
//                   </clipPath>
//                   <clipPath id="clip1_704_3422">
//                     <rect
//                       width="20"
//                       height="20"
//                       fill="white"
//                       transform="translate(8 8)"
//                     />
//                   </clipPath>
//                 </defs>
//               </svg>
//               <svg
//                 onClick={handleNext}
//                 width="36"
//                 height="36"
//                 viewBox="0 0 36 36"
//                 fill="none"
//                 xmlns="http://www.w3.org/2000/svg"
//                 style={{ cursor: "pointer" }}
//               >
//                 <rect width="36" height="36" rx="18" fill="#002A57" />
//                 <g clipPath="url(#clip0_704_3428)">
//                   <g clipPath="url(#clip1_704_3428)">
//                     <path
//                       d="M20.0234 12.9419L25.0817 18.0002L20.0234 23.0586"
//                       stroke="white"
//                       strokeMiterlimit="10"
//                       strokeLinecap="round"
//                       strokeLinejoin="round"
//                     />
//                     <path
//                       d="M10.916 18H24.941"
//                       stroke="white"
//                       strokeMiterlimit="10"
//                       strokeLinecap="round"
//                       strokeLinejoin="round"
//                     />
//                   </g>
//                 </g>
//                 <defs>
//                   <clipPath id="clip0_704_3428">
//                     <rect
//                       width="20"
//                       height="20"
//                       fill="white"
//                       transform="translate(8 8)"
//                     />
//                   </clipPath>
//                   <clipPath id="clip1_704_3428">
//                     <rect
//                       width="20"
//                       height="20"
//                       fill="white"
//                       transform="translate(8 8)"
//                     />
//                   </clipPath>
//                 </defs>
//               </svg>
//             </div>
//           </div>
//         </div>
//         <div
//           style={{
//             display: "flex",
//             gap: "10px",
//             overflowX: isMobile ? "scroll" : "hidden",
//             overflowY: "hidden",
//             position: "relative",
//             height: isMobile ? "auto" : "300px",
//           }}
//         >
//           <div
//             className={`testimonial-container ${
//               transitioning ? "transition" : ""
//             } ${direction === "next" ? "next" : "prev"}`}
//             style={{
//               display: "flex",
//               position: "relative",
//               left: `${-currentSet * 100}%`,
//               transition: "left 0.5s ease-in-out",
//               width: `${totalSets * 100}%`,
//             }}
//           >
//             {testimonials.map((testimonial, index) => (
//               <div
//                 key={index}
//                 className="testimonial-card"
//                 style={{
//                   width: isMobile ? "357px" : "585px",
//                   padding: "24px",
//                   gap: "0px",
//                   background: "rgba(246, 246, 249, 1)",
//                   display: "flex",
//                   flexDirection: "column",
//                   marginRight: !isMobile && index % 2 === 0 ? "30px" : "0px",
//                   boxSizing: "border-box",
//                   flex: "0 0 auto",
//                   height: isMobile ? "313px" : "auto",
//                 }}
//               >
//                 <div
//                   style={{
//                     display: "flex",
//                     flexDirection: "column",
//                     gap: "8px",
//                     width: isMobile ? "100%" : "auto",
//                     height: "auto",
//                   }}
//                 >
//                   <div
//                     style={{
//                       width: "68px",
//                       height: "68px",
//                       borderRadius: "50%",
//                       overflow: "hidden",
//                     }}
//                   >
//                     <img
//                       src={testimonial.image}
//                       alt={testimonial.name}
//                       style={{ width: "100%", height: "100%" }}
//                     />
//                   </div>
//                   <div>
//                     <div
//                       style={{
//                         fontFamily: "Manrope",
//                         fontSize: "16px",
//                         fontWeight: "400",
//                         lineHeight: "27.2px",
//                         color: "rgba(0, 42, 87, 1)",
//                       }}
//                     >
//                       {testimonial.name}
//                     </div>
//                     <div
//                       className="testimonial-location"
//                       style={{
//                         fontFamily: "Manrope",
//                         fontSize: "14px",
//                         fontWeight: "400",
//                         lineHeight: "21px",
//                         color: "rgba(255, 165, 0, 1)",
//                         marginBottom: isMobile ? "0px" : "8px",
//                       }}
//                     >
//                       {testimonial.location}
//                     </div>
//                   </div>
//                 </div>
//                 <div
//                   className="testimonial-text"
//                   style={{
//                     marginTop: isMobile ? "0px" : "8px",
//                     fontFamily: "Open Sans",
//                     fontSize: "18px",
//                     fontStyle: "italic",
//                     fontWeight: "400",
//                     lineHeight: "28.8px",
//                     textAlign: "left",
//                     color: "rgba(67, 62, 63, 1)",
//                     whiteSpace: "normal",
//                     overflow: "visible",
//                     flexGrow: 1,
//                   }}
//                 >
//                   &ldquo;{testimonial.text}&rdquo;
//                 </div>
//               </div>
//             ))}
//           </div>
//         </div>
//         <div
//           style={{
//             display: "flex",
//             gap: "5px",
//             marginTop: "24px",
//             justifyContent: "center",
//           }}
//         >
//           {Array.from({ length: totalSets }).map((_, index) => (
//             <div
//               key={index}
//               style={{
//                 display: "flex",
//                 alignItems: "center",
//                 transition: "transform 0.5s ease-in-out",
//               }}
//             >
//               <svg
//                 width={currentSet === index ? "20" : "6"}
//                 height={currentSet === index ? "7" : "7"}
//                 viewBox={currentSet === index ? "0 0 20 7" : "0 0 6 7"}
//                 fill="none"
//                 xmlns="http://www.w3.org/2000/svg"
//                 style={{
//                   transition: "width 0.5s ease-in-out",
//                 }}
//               >
//                 {currentSet === index ? (
//                   <rect
//                     width="20"
//                     height="6.31579"
//                     rx="3.15789"
//                     fill="#FFA500"
//                   />
//                 ) : (
//                   <circle
//                     opacity="0.7" 
//                     cx="3"
//                     cy="3.15771"
//                     r="3"
//                     fill="#002A57"
//                   />
//                 )}
//               </svg>
//             </div>
//           ))}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default React.memo(TestimonialsSection);

import React, { useState, useEffect } from "react";
import janetImage from "../assets/janet.png";
import robertImage from "../assets/robert.png";

const defaultTestimonials = [
  {
    image: janetImage,
    name: "Janet Henry",
    location: "Springfield, IL",
    text: "Faith Coalition's programs have empowered me to take action and be a part of meaningful change. It's amazing to see our efforts lead to tangible improvements in our community.",
  },
  {
    image: robertImage,
    name: "Robert Johnson",
    location: "Springfield, IL",
    text: "Volunteering with Faith Coalition has been transformative. The community's dedication to social justice inspires me, and together, we're making a real impact.",
  },
  {
    image: janetImage,
    name: "Michael Doe",
    location: "Chicago, IL",
    text: "I have seen firsthand the positive impact Faith Coalition has on the community. Their dedication to making a difference is truly inspiring.",
  },
  {
    image: robertImage,
    name: "Anna Smith",
    location: "New York, NY",
    text: "The support and resources provided by Faith Coalition have been invaluable. I am grateful to be a part of this amazing community.",
  },
];


const TestimonialsSection = () => {
  const [currentSet, setCurrentSet] = useState(0);
  const [transitioning, setTransitioning] = useState(false);
  const [direction, setDirection] = useState("");
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [testimonials, setTestimonials] = useState(defaultTestimonials)

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

    useEffect(() => {
      const fetchtestimonials = async () => {
        try {

          const response = await fetch(
            `${process.env.REACT_APP_BASE_URL}/api/testimonials?populate=*`,
            {
              headers: {
                Authorization: `Bearer ${process.env.REACT_APP_CMS_TOKEN}`,
              },
            }
          );
          const data = await response.json();
          setTestimonials((prev) => [...prev, ...data.data.map((test) => {
            return {
              image: test.attributes?.pic.data.attributes.url,
              name: test.attributes.Name,
              location: test.attributes.location,
              text: test.attributes.comment
            }
          })]);
        } catch (error) {
          console.error("Error fetching testimonials:", error);
        }
      };

  
      fetchtestimonials();

    }, []);

  const testimonialsPerPage = isMobile ? 1 : 2;
  const totalSets = Math.ceil(testimonials.length / testimonialsPerPage);

  const handleNext = () => {
    if (!transitioning) {
      setDirection("next");
      setTransitioning(true);
      setTimeout(() => {
        setCurrentSet((prevSet) => (prevSet + 1) % totalSets);
        setTransitioning(false);
      }, 500);
    }
  };

  const handlePrev = () => {
    if (!transitioning) {
      setDirection("prev");
      setTransitioning(true);
      setTimeout(() => {
        setCurrentSet((prevSet) => (prevSet - 1 + totalSets) % totalSets);
        setTransitioning(false);
      }, 500);
    }
  };

  return (
    <div className="w-full h-auto py-[50px] px-[20px] gap-[32px] opacity-100 overflow-hidden flex justify-center">
      <style>
        {`
          .slide-in-right {
            animation: slideInRight 0.5s forwards;
          }
          .slide-out-left {
            animation: slideOutLeft 0.5s forwards;
          }
          .slide-in-left {
            animation: slideInLeft 0.5s forwards;
          }
          .slide-out-right {
            animation: slideOutRight 0.5s forwards;
          }
          @keyframes slideInRight {
            from {
              transform: translateX(100%);
            }
            to {
              transform: translateX(0);
            }
          }
          @keyframes slideOutLeft {
            from {
              transform: translateX(0);
            }
            to {
              transform: translateX(-100%);
            }
          }
          @keyframes slideInLeft {
            from {
              transform: translateX(-100%);
            }
            to {
              transform: translateX(0);
            }
          }
          @keyframes slideOutRight {
            from {
              transform: translateX(0);
            }
            to {
              transform: translateX(100%);
            }
          }
        `}
      </style>
      <div
        className={`w-full ${isMobile ? "max-w-full" : "max-w-[1200px]"} gap-[32px] flex flex-col`}
      >
        <div className="flex flex-col gap-[24px]">
          <div className="flex items-center gap-[10px]">
            <div className="w-[72px] h-[2px] bg-custom-orange"></div>
            <div className="font-primary text-[16px] font-bold leading-[21.86px] tracking-[2px] text-left text-custom-orange">
              TESTIMONIALS
            </div>
          </div>
          <div className="flex items-center justify-between">
            <div className="font-primary text-[32px] font-bold leading-[38.4px] text-left text-custom-blue">
              Feedback from community members
            </div>
            <div className="arrow flex gap-[8px]">
            <svg
                onClick={handlePrev}
                width="36"
                height="36"
                viewBox="0 0 36 36"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={{ cursor: "pointer" }}
              >
                <rect width="36" height="36" rx="18" fill="#002A57" />
                <g clipPath="url(#clip0_704_3422)">
                  <g clipPath="url(#clip1_704_3422)">
                    <path
                      d="M15.9744 12.9419L10.916 18.0002L15.9744 23.0586"
                      stroke="white"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M25.0836 18H11.0586"
                      stroke="white"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </g>
                </g>
                <defs>
                  <clipPath id="clip0_704_3422">
                    <rect
                      width="20"
                      height="20"
                      fill="white"
                      transform="translate(8 8)"
                    />
                  </clipPath>
                  <clipPath id="clip1_704_3422">
                    <rect
                      width="20"
                      height="20"
                      fill="white"
                      transform="translate(8 8)"
                    />
                  </clipPath>
                </defs>
              </svg>
              <svg
                onClick={handleNext}
                width="36"
                height="36"
                viewBox="0 0 36 36"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={{ cursor: "pointer" }}
              >
                <rect width="36" height="36" rx="18" fill="#002A57" />
                <g clipPath="url(#clip0_704_3428)">
                  <g clipPath="url(#clip1_704_3428)">
                    <path
                      d="M20.0234 12.9419L25.0817 18.0002L20.0234 23.0586"
                      stroke="white"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M10.916 18H24.941"
                      stroke="white"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </g>
                </g>
                <defs>
                  <clipPath id="clip0_704_3428">
                    <rect
                      width="20"
                      height="20"
                      fill="white"
                      transform="translate(8 8)"
                    />
                  </clipPath>
                  <clipPath id="clip1_704_3428">
                    <rect
                      width="20"
                      height="20"
                      fill="white"
                      transform="translate(8 8)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </div>
          </div>
        </div>
        <div
          className={`flex gap-[10px] overflow-y-hidden relative ${
            isMobile ? "overflow-x-scroll" : "overflow-x-hidden"
          } ${isMobile ? "h-auto" : "h-[300px]"}`}
        >
          <div
            className={`testimonial-container flex relative transition-all duration-500 ease-in-out`}
            style={{
              left: `${-currentSet * 100}%`,
              width: `${totalSets * 100}%`,
            }}
          >
            {testimonials.map((testimonial, index) => (
              <div
                key={index}
                className={`testimonial-card flex flex-col flex-shrink-0 bg-custom-bg-gray overflow-hidden box-border ${
                  isMobile
                    ? "w-[357px] h-[313px] p-[24px] mr-[34px]"
                    : "w-[585px] p-[24px]"
                } ${!isMobile && index % 2 === 0 ? "mr-[30px]" : ""}`}
              >
                <div
                  className={`flex flex-col gap-[8px] ${
                    isMobile ? "w-full" : "w-auto"
                  }`}
                >
                  <div className="w-[68px] h-[68px] rounded-full overflow-hidden">
                    <img
                      src={testimonial.image}
                      alt={testimonial.name}
                      className="w-full h-full"
                    />
                  </div>
                  <div>
                    <div className="font-primary text-[16px] font-normal leading-[27.2px] text-custom-blue">
                      {testimonial.name}
                    </div>
                    <div
                      className={`testimonial-location font-primary text-[14px] font-normal leading-[21px] text-custom-orange ${
                        isMobile ? "mb-0" : "mb-[8px]"
                      }`}
                    >
                      {testimonial.location}
                    </div>
                  </div>
                </div>
                <div
                  className={`testimonial-text mt-[8px] ${
                    isMobile ? "mt-0" : ""
                  } font-secondary text-[18px] italic font-normal leading-[28.8px] text-left text-custom-lightgray flex-grow`}
                >
                  &ldquo;{testimonial.text}&rdquo;
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="flex gap-[5px] mt-[24px] justify-center">
          {Array.from({ length: totalSets }).map((_, index) => (
            <div
              key={index}
              className="flex items-center transition-transform duration-500 ease-in-out"
            >
              <svg
                width={currentSet === index ? "20" : "6"}
                height="7"
                viewBox={currentSet === index ? "0 0 20 7" : "0 0 6 7"}
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="transition-width duration-500 ease-in-out"
              >
                {currentSet === index ? (
                  <rect
                    width="20"
                    height="6.31579"
                    rx="3.15789"
                    fill="#FFA500"
                  />
                ) : (
                  <circle
                    opacity="0.7"
                    cx="3"
                    cy="3.15771"
                    r="3"
                    fill="#002A57"
                  />
                )}
              </svg>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default React.memo(TestimonialsSection);
