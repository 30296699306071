import React from "react";
import backgroundImage from "../assets/newsletter.jpeg";

const Newsletter = () => {
  return (
    <div className="flex justify-center py-8 px-4">
      <div
        className="relative flex items-center justify-center rounded-[20px] w-full max-w-[1040px] h-[267px] bg-cover bg-center"
        style={{ backgroundImage: `url(${backgroundImage})` }}
      >
        <div className="absolute inset-0 bg-black opacity-50 rounded-[20px]" />
        <div className="relative text-center max-w-[90%] md:max-w-[80%] p-4 md:p-8">
          <div
            className="uppercase mb-2 text-center mx-auto text-[16px] font-bold leading-[21.86px] text-[#FFA500]"
            style={{ fontFamily: "Manrope" }}
          >
            NEWSLETTER
          </div>
          <h1
            className="text-white text-[24px] md:text-[32px] font-bold leading-[30px] md:leading-[43.71px] text-center"
            style={{ fontFamily: "Manrope", fontWeight: "600" }}
          >
            STAY INFORMED
          </h1>
          <p
            className="text-white mt-4 text-[14px] md:text-[16px] font-normal leading-[20px] md:leading-[32px] text-center"
            style={{ fontFamily: "Manrope", fontWeight: "400" }}
          >
            Be the first to hear about our latest initiatives, events, and
            community news
          </p>
          <iframe
            src="https://secure.everyaction.com/sGqe7GaiwUK7FNPXxSG7Rw2"
            className="w-full flex-1 h-full"
          />
          {/* <div
            className="ngp-form"
            data-form-url="https://secure.everyaction.com/v1/Forms/sGqe7GaiwUK7FNPXxSG7Rw2"
            data-custom-css="https://nvlupin.blob.core.windows.net/designs/CustomStylesheet_59b9b6652237f4d3f99e4a4fd4ff8226a80b0c8492a2499f229bb760a7026c18.css"
            data-fastaction-endpoint="https://fastaction.ngpvan.com"
            data-inline-errors="true"
            data-fastaction-nologin="true"
            data-mobile-autofocus="false"
          ></div> */}
          {/* <div className="mt-4 flex flex-col md:flex-row items-center justify-center space-y-4 md:space-y-0 md:space-x-4">
            <input
              type="email"
              placeholder="Enter your email address"
              className="w-full max-w-[250px] md:max-w-[400px] h-[48px] p-3 rounded-full border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-600 placeholder-gray-500"
              style={{ fontFamily: "Manrope", fontSize: '13px', fontWeight: '400', lineHeight: '17.76px', color: 'rgba(189, 189, 189, 1)' }}
            />
            <button
              className="w-full max-w-[120px] md:max-w-[152px] h-[48px] bg-[#002A57] text-white rounded-full p-3 focus:outline-none focus:ring-2 focus:ring-blue-600"
              style={{ fontFamily: "Manrope", fontSize: '13px', fontWeight: '500', lineHeight: '18px', color: 'rgba(255, 255, 255, 1)' }}
            >
              Subscribe
            </button>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Newsletter;
