import React, { useState } from "react";
import OurMemberImage from "../assets/ourMemberBg.png";
import VectorImage from "../assets/Vector.png";
import bMemberImage from "../assets/bMember.png";
import Newsletter from "./newsLetter";
import { useNavigate } from "react-router-dom";

const OurMember = () => {
  const navigate = useNavigate();
  const [isClicked, setIsClicked] = useState(false);

  const memberData = [
    {
      churchName: "Abraham Lincoln Unitarian Universalist Church",
      pastorName: "Pastor Sebastian Matthews",
      address: "745 Woodside Road Springfield, IL 62711",
      website: "https://example.com",
    },
    {
      churchName: "Community Church",
      pastorName: "Pastor John Doe",
      address: "123 Main Street Springfield, IL 62711",
      website: "https://example.com",
    },
    {
      churchName: "Faith Church",
      pastorName: "Pastor Jane Smith",
      address: "456 Elm Street Springfield, IL 62711",
      website: "https://example.com",
    },
    {
      churchName: "Grace Church",
      pastorName: "Pastor Michael Johnson",
      address: "789 Oak Street Springfield, IL 62711",
      website: "https://example.com",
    },
    {
      churchName: "Hope Church",
      pastorName: "Pastor Emily Davis",
      address: "101 Pine Street Springfield, IL 62711",
      website: "https://example.com",
    },
    {
      churchName: "Life Church",
      pastorName: "Pastor Sarah Brown",
      address: "202 Maple Street Springfield, IL 62711",
      website: "https://example.com",
    },
    {
      churchName: "Faith Church",
      pastorName: "Pastor Jane Smith",
      address: "456 Elm Street Springfield, IL 62711",
      website: "https://example.com",
    },
    {
      churchName: "Grace Church",
      pastorName: "Pastor Michael Johnson",
      address: "789 Oak Street Springfield, IL 62711",
      website: "https://example.com",
    },
    {
      churchName: "Hope Church",
      pastorName: "Pastor Emily Davis",
      address: "101 Pine Street Springfield, IL 62711",
      website: "https://example.com",
    },
    {
      churchName: "Life Church",
      pastorName: "Pastor Sarah Brown",
      address: "202 Maple Street Springfield, IL 62711",
      website: "https://example.com",
    },
  ];

  const [visibleCount, setVisibleCount] = useState(9);

  const handleViewMore = () => {
    setVisibleCount(memberData.length);
  };

  const handleLearnMoreClick = () => {
    setIsClicked(true);
    setTimeout(() => {
      navigate("/becomeMember");
    }, 300);
  };
  //   return (
  //     <>
  //       <div
  //         style={{
  //           position: "relative",
  //           width: "100%",
  //           height: "350px",
  //           marginTop: "80px",
  //           overflow: "hidden",
  //         }}
  //       >
  //         <img
  //           src={OurMemberImage}
  //           alt="Our Member"
  //           style={{
  //             width: "100%",
  //             height: "100%",
  //             objectFit: "cover",
  //             objectPosition: "50% 36.5%",
  //             position: "absolute",
  //             top: 0,
  //             left: 0,
  //           }}
  //         />
  //         <div
  //           style={{
  //             width: "100%",
  //             height: "100%",
  //             background: "rgba(0, 0, 0, 0.7)",
  //             position: "absolute",
  //             top: 0,
  //             left: 0,
  //           }}
  //         ></div>
  //         <div
  //           style={{
  //             position: "relative",
  //             width: "774px",
  //             margin: "0 auto",
  //             top: "80px",
  //             display: "flex",
  //             flexDirection: "column",
  //             alignItems: "center",
  //             gap: "24px",
  //             opacity: 1,
  //           }}
  //         >
  //           <div
  //             style={{
  //               width: "774px",
  //               height: "77px",
  //               opacity: 1,
  //               fontFamily: "Manrope",
  //               fontSize: "64px",
  //               fontWeight: "700",
  //               lineHeight: "76.8px",
  //               textAlign: "center",
  //               color: "rgba(250, 250, 250, 1)",
  //             }}
  //           >
  //             Our Members
  //           </div>
  //           <div
  //             style={{
  //               width: "577px",
  //               height: "52px",
  //               opacity: 1,
  //               fontFamily: "Manrope",
  //               fontSize: "18px",
  //               fontWeight: "400",
  //               lineHeight: "25.6px",
  //               textAlign: "center",
  //               color: "rgba(250, 250, 250, 1)",
  //             }}
  //           >
  //             Meet the diverse organizations that support and strengthen our
  //             mission for social justice and community empowerment
  //           </div>
  //         </div>
  //       </div>

  //       <div
  //         style={{
  //           width: "100vw",
  //           padding: "50px 120px",
  //           gap: "32px",
  //           background: "rgba(246, 246, 249, 1)",
  //           opacity: 1,
  //         }}
  //       >
  //         <div
  //           style={{
  //             width: "1200px",
  //             margin: "0 auto",
  //             gap: "32px",
  //             display: "flex",
  //             flexDirection: "column",
  //           }}
  //         >
  //           {[...Array(Math.ceil(visibleCount / 3))].map((_, rowIndex) => (
  //             <div
  //               key={rowIndex}
  //               style={{
  //                 width: "1200px",
  //                 height: "auto",
  //                 display: "flex",
  //                 gap: "30px",
  //               }}
  //             >
  //               {memberData
  //                 .slice(rowIndex * 3, rowIndex * 3 + 3)
  //                 .map((member, boxIndex) => (
  //                   <div
  //                     key={boxIndex}
  //                     style={{
  //                       width: "380px",
  //                       height: "auto",
  //                       background: "rgba(255, 255, 255, 1)",
  //                       padding: "24px",
  //                       borderRadius: "20px",
  //                       border: "1px solid rgba(227, 227, 227, 1)",
  //                       display: "flex",
  //                       flexDirection: "column",
  //                       justifyContent: "space-between",
  //                     }}
  //                   >
  //                     <div>
  //                       <div
  //                         style={{
  //                           width: "286px",
  //                           height: "auto",
  //                           fontFamily: "Manrope",
  //                           fontSize: "17px",
  //                           fontWeight: "600",
  //                           lineHeight: "24px",
  //                           textAlign: "left",
  //                           color: "rgba(0, 42, 87, 1)",
  //                           marginBottom: "8px",
  //                         }}
  //                       >
  //                         {member.churchName}
  //                       </div>
  //                       <div
  //                         style={{
  //                           display: "flex",
  //                           alignItems: "center",
  //                           gap: "8px",
  //                           fontFamily: "Manrope",
  //                           fontSize: "16px",
  //                           fontWeight: "400",
  //                           lineHeight: "24px",
  //                           textAlign: "left",
  //                           color: "rgba(67, 62, 63, 1)",
  //                           marginBottom: "8px",
  //                         }}
  //                       >
  //                         <svg
  //                           width="16"
  //                           height="16"
  //                           viewBox="0 0 16 16"
  //                           fill="none"
  //                           xmlns="http://www.w3.org/2000/svg"
  //                         >
  //                           <g clipPath="url(#clip0_203_20451)">
  //                             <path
  //                               d="M8 0C5.67378 0 3.78125 1.89253 3.78125 4.21875C3.78125 6.54497 5.67378 8.4375 8 8.4375C10.3262 8.4375 12.2188 6.54497 12.2188 4.21875C12.2188 1.89253 10.3262 0 8 0Z"
  //                               fill="#FFA500"
  //                             />
  //                             <path
  //                               d="M13.2489 11.1936C12.0939 10.0208 10.5628 9.375 8.9375 9.375H7.0625C5.43725 9.375 3.90606 10.0208 2.75106 11.1936C1.60172 12.3606 0.96875 13.901 0.96875 15.5312C0.96875 15.7901 1.17862 16 1.4375 16H14.5625C14.8214 16 15.0312 15.7901 15.0312 15.5312C15.0312 13.901 14.3983 12.3606 13.2489 11.1936Z"
  //                               fill="#FFA500"
  //                             />
  //                           </g>
  //                           <defs>
  //                             <clipPath id="clip0_203_20451">
  //                               <rect width="16" height="16" fill="white" />
  //                             </clipPath>
  //                           </defs>
  //                         </svg>
  //                         {member.pastorName}
  //                       </div>
  //                       <div
  //                         style={{
  //                           display: "flex",
  //                           alignItems: "center",
  //                           gap: "8px",
  //                           fontFamily: "Manrope",
  //                           fontSize: "16px",
  //                           fontWeight: "400",
  //                           lineHeight: "24px",
  //                           textAlign: "left",
  //                           color: "rgba(67, 62, 63, 1)",
  //                           marginBottom: "16px",
  //                         }}
  //                       >
  //                         <svg
  //                           width="12"
  //                           height="16"
  //                           viewBox="0 0 12 16"
  //                           fill="none"
  //                           xmlns="http://www.w3.org/2000/svg"
  //                         >
  //                           <path
  //                             d="M6 0C2.7 0 0.015625 2.68438 0.015625 5.98438C0.015625 7.05937 0.521875 8.41563 1.55938 10.1313C2.42188 11.5563 3.49063 12.9719 4.43125 14.2188C4.86562 14.7937 5.275 15.3375 5.59062 15.7875C5.68437 15.9219 5.8375 16 6 16C6.1625 16 6.31563 15.9219 6.40938 15.7875C6.725 15.3375 7.13438 14.7937 7.56875 14.2188C8.50938 12.9719 9.575 11.5563 10.4406 10.1313C11.4781 8.41563 11.9844 7.05937 11.9844 5.98438C11.9844 2.68438 9.3 0 6 0ZM6 8.75C4.475 8.75 3.23438 7.50938 3.23438 5.98438C3.23438 4.45937 4.475 3.21875 6 3.21875C7.525 3.21875 8.76562 4.45937 8.76562 5.98438C8.76562 7.50938 7.525 8.75 6 8.75Z"
  //                             fill="#FFA500"
  //                           />
  //                         </svg>
  //                         {member.address}
  //                       </div>
  //                     </div>
  //                     <div
  //                       style={{
  //                         display: "flex",
  //                         alignItems: "center",
  //                         gap: "5px",
  //                         marginTop: "auto",
  //                         marginBottom: "2px",
  //                       }}
  //                     >
  //                       <a
  //                         href={member.website}
  //                         style={{
  //                           fontFamily: "Manrope",
  //                           fontSize: "14px",
  //                           fontWeight: "400",
  //                           lineHeight: "18px",
  //                           textAlign: "center",
  //                           color: "rgba(255, 165, 0, 1)",
  //                           textDecoration: "none",
  //                         }}
  //                       >
  //                         Visit Website
  //                       </a>
  //                       <svg
  //                         width="24"
  //                         height="24"
  //                         viewBox="0 0 24 24"
  //                         fill="none"
  //                         xmlns="http://www.w3.org/2000/svg"
  //                       >
  //                         <path
  //                           fillRule="evenodd"
  //                           clipRule="evenodd"
  //                           d="M8.35731 16.1874C8.22969 16.4265 8.29933 16.7311 8.52942 16.8943C8.77868 17.071 9.12233 17.0151 9.29649 16.7695L14.8426 8.94793L15.6947 13.7986L15.7124 13.8712C15.7945 14.1297 16.0578 14.2965 16.3326 14.2527C16.6319 14.2046 16.8323 13.9232 16.7797 13.6247L15.6972 7.46322L15.6798 7.39143C15.6431 7.27488 15.5683 7.17479 15.4705 7.10541C15.412 7.064 15.3457 7.03386 15.2743 7.01713C15.0563 6.96598 14.8313 7.04981 14.7034 7.23017L8.39335 16.1291L8.35731 16.1874ZM8.44057 8.65675C8.49475 8.9555 8.78074 9.15829 9.08023 9.10853L12.4847 8.54748L12.5576 8.53044C12.8162 8.45109 12.9791 8.19162 12.9294 7.91777C12.9024 7.76839 12.8181 7.64354 12.7031 7.562C12.5869 7.4796 12.4406 7.44094 12.2898 7.46599L8.88569 8.02644L8.813 8.0436C8.55476 8.12335 8.39146 8.38329 8.44057 8.65675Z"
  //                           fill="#FFA500"
  //                         />
  //                       </svg>
  //                     </div>
  //                   </div>
  //                 ))}
  //             </div>
  //           ))}
  //         </div>
  //         {visibleCount < memberData.length && (
  //           <div
  //             style={{
  //               width: "171px",
  //               height: "48px",
  //               margin: "40px auto 0",
  //               padding: "16px 0",
  //               gap: "10px",
  //               borderRadius: "30px 30px 30px 30px",
  //               background: "rgba(0, 42, 87, 1)",
  //               display: "flex",
  //               justifyContent: "center",
  //               alignItems: "center",
  //               cursor: "pointer",
  //             }}
  //             onClick={handleViewMore}
  //           >
  //             <div
  //               style={{
  //                 fontFamily: "Manrope",
  //                 fontSize: "14px",
  //                 fontWeight: "500",
  //                 lineHeight: "18px",
  //                 textAlign: "center",
  //                 color: "rgba(255, 255, 255, 1)",
  //               }}
  //             >
  //               View More
  //             </div>
  //           </div>
  //         )}
  //       </div>
  //       <div className="relative w-full h-[645px] bg-white" style={{ backgroundImage: `url(${VectorImage})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
  //   <div className="relative w-full h-[569px]" >
  //     <div className="absolute w-[585px] h-auto" style={{ left: '120px', right: '120px', top: '144.5px', bottom: '139.5px' }}>
  //       <div className="flex flex-col">
  //         <div className="flex items-center" style={{ gap: '3px', marginBottom: '24px' }}>
  //           <div style={{
  //             width: '72px',
  //             height: '0px',
  //             borderTop: '2px solid rgba(255, 165, 0, 1)',
  //           }}></div>
  //           <h2 style={{
  //             fontFamily: 'Manrope',
  //             fontSize: '16px',
  //             fontWeight: '700',
  //             lineHeight: '21.86px',
  //             letterSpacing: '2px',
  //             textAlign: 'left',
  //             color: 'rgba(255, 165, 0, 1)' ,
  //             paddingLeft:"24px"
  //           }}>
  //             BECOME A MEMBER
  //           </h2>
  //         </div>

  //         <div className="w-[534px] h-[100px] flex flex-col" style={{ marginBottom: '24px' }}>
  //           <h1 style={{
  //             fontFamily: 'Manrope',
  //             fontSize: '42px',
  //             fontWeight: '700',
  //             lineHeight: '50.4px',
  //             textAlign: 'left',
  //             color: 'rgba(0, 42, 87, 1)'
  //           }}>
  //             Would you like to become a member?
  //           </h1>
  //         </div>

  //         <div className="w-[585px] h-[116px] flex flex-col" style={{ marginBottom: '32px' }}>
  //           <p style={{
  //             fontFamily: 'Open Sans',
  //             fontSize: '18px',
  //             fontWeight: '400',
  //             lineHeight: '28.8px',
  //             textAlign: 'left',
  //             color: 'rgba(67, 62, 63, 1)'
  //           }}>
  //             We invite all who share the values of racial and economic equity to join us. Congregations, organizations, and individuals can become members to advance social justice in Springfield and beyond. Below is a role of FCCG members.
  //           </p>
  //         </div>

  //         <button
  //       style={{
  //         width: '171px',
  //         height: '48px',
  //         padding: '16px 0',
  //         backgroundColor: 'rgba(0, 42, 87, 1)',
  //         borderRadius: '30px',
  //         color: 'white',
  //         fontFamily: 'Manrope',
  //         fontSize: '14px',
  //         fontWeight: '500',
  //         lineHeight: '18px',
  //         textAlign: 'center',
  //         transition: 'all 0.3s ease-in-out',
  //         opacity: isClicked ? 0.7 : 1,
  //         transform: isClicked ? 'scale(1.05)' : 'scale(1)',
  //       }}
  //       onClick={handleLearnMoreClick}
  //     >
  //       Learn More
  //     </button>
  //       </div>
  //     </div>

  //     <div className="absolute" style={{ width: '539px', height: '413px', top: '113px', right: '120px', bottom: '119px', borderRadius: '20px' }}>
  //       <img src={bMemberImage} alt="Become a Member" className="w-full h-full object-cover" style={{ borderRadius: '20px',objectPosition: '70% 5%'  }} />
  //     </div>
  //   </div>
  // </div>
  // <Newsletter/>
  //     </>
  //   );
  // };

  return (
    <>
      {/* Header Section */}
      <div className="relative w-full h-[350px] mt-[80px] overflow-hidden">
        <img
          src={OurMemberImage}
          alt="Our Member"
          className="absolute w-full h-full object-cover"
          style={{ objectPosition: "50% 36.5%" }}
        />
        <div className="absolute w-full h-full bg-black bg-opacity-70"></div>
        <div
          className="relative w-full max-w-[774px] mx-auto top-[80px] flex flex-col items-center gap-[24px] px-4"
          style={{ opacity: 1 }}
        >
          <div
            className="w-full md:w-[774px] h-auto font-primary font-bold text-[32px] md:text-[64px] leading-[38px] md:leading-[76.8px] text-center text-white"
            style={{ opacity: 1 }}
          >
            Our Members
          </div>
          <div
            className="w-full md:w-[577px] h-auto font-primary font-normal text-[16px] md:text-[18px] leading-[22px] md:leading-[25.6px] text-center text-white"
            style={{ opacity: 1 }}
          >
            Meet the diverse organizations that support and strengthen our
            mission for social justice and community empowerment
          </div>
        </div>
      </div>

      {/* Members Section */}
      <div
        className="w-full  px-4 xl:px-[120px] py-[50px] gap-4 xl:gap-[32px] bg-[#F6F6F9]"
        style={{ opacity: 1 }}
      >
        <div className="w-full max-w-[1200px] mx-auto flex flex-col gap-4 xl:gap-[32px]">
          {/* Flex Container */}
          <div className="flex flex-col gap-4 xl:gap-[32px]">
            <div className="w-full xl:w-[1200px] flex flex-col flex-wrap md:flex-row gap-4 xl:gap-[32px]">
              {memberData.map((member, boxIndex) => (
                <div
                  key={boxIndex}
                  className="w-full max-w-[370px] md:max-w-[350px] bg-white p-[24px] rounded-lg border border-[#E3E3E3] flex flex-col justify-between"
                >
                  <div>
                    <div className="w-full md:w-[286px] font-primary font-semibold text-[17px] leading-[24px] text-left text-custom-blue mb-[8px]">
                      {member.churchName}
                    </div>
                    <div className="flex items-center gap-[8px] font-primary font-normal text-[16px] leading-[24px] text-left text-custom-lightgray mb-[8px]">
                      {/* Pastor Icon */}
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clipPath="url(#clip0_203_20451)">
                          <path
                            d="M8 0C5.67378 0 3.78125 1.89253 3.78125 4.21875C3.78125 6.54497 5.67378 8.4375 8 8.4375C10.3262 8.4375 12.2188 6.54497 12.2188 4.21875C12.2188 1.89253 10.3262 0 8 0Z"
                            fill="#FFA500"
                          />
                          <path
                            d="M13.2489 11.1936C12.0939 10.0208 10.5628 9.375 8.9375 9.375H7.0625C5.43725 9.375 3.90606 10.0208 2.75106 11.1936C1.60172 12.3606 0.96875 13.901 0.96875 15.5312C0.96875 15.7901 1.17862 16 1.4375 16H14.5625C14.8214 16 15.0312 15.7901 15.0312 15.5312C15.0312 13.901 14.3983 12.3606 13.2489 11.1936Z"
                            fill="#FFA500"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_203_20451">
                            <rect width="16" height="16" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                      {member.pastorName}
                    </div>
                    <div className="flex items-center gap-[8px] font-primary font-normal text-[16px] leading-[24px] text-left text-custom-lightgray mb-[16px]">
                      {/* Address Icon */}
                      <svg
                        width="12"
                        height="16"
                        viewBox="0 0 12 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6 0C2.7 0 0.015625 2.68438 0.015625 5.98438C0.015625 7.05937 0.521875 8.41563 1.55938 10.1313C2.42188 11.5563 3.49063 12.9719 4.43125 14.2188C4.86562 14.7937 5.275 15.3375 5.59062 15.7875C5.68437 15.9219 5.8375 16 6 16C6.1625 16 6.31563 15.9219 6.40938 15.7875C6.725 15.3375 7.13438 14.7937 7.56875 14.2188C8.50938 12.9719 9.575 11.5563 10.4406 10.1313C11.4781 8.41563 11.9844 7.05937 11.9844 5.98438C11.9844 2.68438 9.3 0 6 0ZM6 8.75C4.475 8.75 3.23438 7.50938 3.23438 5.98438C3.23438 4.45937 4.475 3.21875 6 3.21875C7.525 3.21875 8.76562 4.45937 8.76562 5.98438C8.76562 7.50938 7.525 8.75 6 8.75Z"
                          fill="#FFA500"
                        />
                      </svg>
                      {member.address}
                    </div>
                  </div>
                  <div className="flex items-center gap-[5px] mt-auto mb-[2px]">
                    <a
                      href={member.website}
                      className="font-primary font-normal text-[14px] leading-[18px] text-center text-custom-orange no-underline"
                    >
                      Visit Website
                    </a>
                    {/* External Link Icon */}
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M8.35731 16.1874C8.22969 16.4265 8.29933 16.7311 8.52942 16.8943C8.77868 17.071 9.12233 17.0151 9.29649 16.7695L14.8426 8.94793L15.6947 13.7986L15.7124 13.8712C15.7945 14.1297 16.0578 14.2965 16.3326 14.2527C16.6319 14.2046 16.8323 13.9232 16.7797 13.6247L15.6972 7.46322L15.6798 7.39143C15.6431 7.27488 15.5683 7.17479 15.4705 7.10541C15.412 7.064 15.3457 7.03386 15.2743 7.01713C15.0563 6.96598 14.8313 7.04981 14.7034 7.23017L8.39335 16.1291L8.35731 16.1874ZM8.44057 8.65675C8.49475 8.9555 8.78074 9.15829 9.08023 9.10853L12.4847 8.54748L12.5576 8.53044C12.8162 8.45109 12.9791 8.19162 12.9294 7.91777C12.9024 7.76839 12.8181 7.64354 12.7031 7.562C12.5869 7.4796 12.4406 7.44094 12.2898 7.46599L8.88569 8.02644L8.813 8.0436C8.55476 8.12335 8.39146 8.38329 8.44057 8.65675Z"
                        fill="#FFA500"
                      />
                    </svg>
                  </div>
                </div>
              ))}
            </div>
          </div>
          {visibleCount < memberData.length && (
            <div
              className="w-[171px] h-[48px] mt-[40px] mx-auto py-[16px] gap-[10px] rounded-xl bg-custom-blue flex justify-center items-center cursor-pointer"
              onClick={handleViewMore}
            >
              <div className="font-primary font-medium text-[14px] leading-[18px] text-center text-white">
                View More
              </div>
            </div>
          )}
        </div>
      </div>

      {/* Become a Member Section */}
      <div
        className="relative w-full h-auto md:h-[645px] bg-white"
        style={{
          backgroundImage: `url(${VectorImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="relative w-full max-w-[1440px] mx-auto h-auto md:h-[569px]">
          <div className="flex flex-col md:flex-row items-center md:items-start w-full h-full">
            {/* Image */}
            <div
              className="w-full md:w-[539px] h-auto md:h-[413px] px-4 md:px-0 mt-8 md:mt-0 md:absolute md:top-[113px] md:right-[120px] order-1 md:order-2"
              style={{ opacity: 1 }}
            >
              <img
                src={bMemberImage}
                alt="Become a Member"
                className="w-full h-full object-cover rounded-lg"
                style={{ objectPosition: "70% 5%" }}
              />
            </div>

            {/* Text Content */}
            <div
              className="w-full md:w-[585px] h-auto px-4 md:px-0 md:absolute md:left-[120px] md:top-[144.5px] order-2 md:order-1"
              style={{ opacity: 1 }}
            >
              <div className="flex flex-col">
                <div
                  className="flex items-center mb-[24px]"
                  style={{ gap: "3px" }}
                >
                  <div className="w-[72px] h-0 border-t-[2px] border-custom-orange"></div>
                  <h2 className="font-primary font-bold text-[16px] leading-[21.86px] tracking-[2px] text-custom-orange pl-[24px]">
                    BECOME A MEMBER
                  </h2>
                </div>

                <div className="w-full md:w-[534px] flex flex-col mb-[24px]">
                  <h1 className="font-primary font-bold text-[28px] md:text-[42px] leading-[36px] md:leading-[50.4px] text-left text-custom-blue">
                    Would you like to become a member?
                  </h1>
                </div>

                <div className="w-full md:w-[585px] flex flex-col mb-[32px]">
                  <p className="font-secondary font-normal text-[16px] md:text-[18px] leading-[24px] md:leading-[28.8px] text-left text-custom-lightgray">
                    We invite all who share the values of racial and economic
                    equity to join us. Congregations, organizations, and
                    individuals can become members to advance social justice in
                    Springfield and beyond. Below is a role of FCCG members.
                  </p>
                </div>

                <button
                  className={`w-[171px] h-[48px] py-[16px] bg-custom-blue rounded-xl text-white font-primary font-medium text-[14px] leading-[18px] text-center transition-all duration-300 ease-in-out ${
                    isClicked ? "opacity-70 scale-105" : ""
                  }`}
                  onClick={handleLearnMoreClick}
                >
                  Learn More
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Newsletter />
    </>
  );
};

export default OurMember;
