import React, { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import NavBarImage from "../assets/NabBarImage.png";

const Navbar = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(null);

  const location = useLocation();

  const isLandingPage = location.pathname === "/";
  const isContactPage = location.pathname === "/contact";
  const isVolunteerPage = location.pathname === "/volunteer";
  const isWhatWeDoPage = location.pathname === "/whatWeDo";
  const isTaskforcesPage = location.pathname.startsWith("/taskforces/");
  const isOurMemberPage = location.pathname === "/ourMember";
  const isBecomeMemberPage = location.pathname === "/becomeMember";
  const isBlogPage = location.pathname === "/blog";
  const isAboutUsPage = location.pathname === "/aboutUs";
  const isEventsPage = location.pathname === "/events";
  const isEventDetailsPage = location.pathname.startsWith("/events/");

  const toggleDropdown = (menu) => {
    setDropdownOpen((prevState) => (prevState === menu ? null : menu));
  };

  const toggleMobileMenu = () => {
    setMenuOpen(!menuOpen);
    setDropdownOpen(null);
  };

  const closeDropdown = () => {
    setDropdownOpen(null);
  };

  return (
    <nav
      className={`absolute top-0 left-0 right-0 z-50 ${
        isLandingPage && !menuOpen
          ? "md:bg-transparent bg-white"
          :  (isEventsPage || isEventDetailsPage|| isTaskforcesPage) && !menuOpen
          ? "md:bg-transparent bg-white"
          : "bg-white shadow-md"
      }`}
    >
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 flex items-center h-20">
        {/* Logo */}
        <div
          className="flex-shrink-0"
          style={{ paddingTop: "12px", paddingBottom: "12px" }}
        >
          <NavLink
            to="/#hero-section"
            className="block"
            onClick={closeDropdown}
          >
            <img
              src={NavBarImage}
              alt="Logo"
              className="block md:hidden w-10 h-10"
            />
            <img
              src={NavBarImage}
              alt="Logo"
              className="hidden md:block w-14 h-14"
            />
          </NavLink>
        </div>
        {/* Navigation Links */}
        <div
          className="flex-grow hidden lg:flex items-center justify-center space-x-8 relative"
        >
          <div className="relative flex items-center">
            <NavLink
              to="#"
              className={`text-xs  lg:text-sm xl:text-base font-medium leading-[21.86px] ${
                isAboutUsPage
                  ? "text-[#FFA500]"
                  : isOurMemberPage
                  ? "text-[#FFA500]"
                  : isLandingPage
                  ? "text-white"
                  :  (isEventsPage || isEventDetailsPage || isTaskforcesPage ) 
                  ? "text-white"
                  : "text-gray-800"
              } hover:text-[#FFA500] flex items-center`}
              style={{ fontFamily: "Manrope", fontWeight: "500" }}
              onClick={() => toggleDropdown("whoWeAre")}
              activeClassName="text-[#FFA500]"
            >
              Who We Are
              <svg
                width="12"
                height="9"
                viewBox="0 0 12 9"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className={`ml-2`}
              >
                <path
                  d="M11.3509 3.46198L6.28841 7.66573L1.22591 3.46198C1.16847 3.41225 1.09363 3.38738 1.01786 3.39283C0.94208 3.39828 0.871573 3.43361 0.821845 3.49104C0.772117 3.54848 0.747242 3.62332 0.752692 3.69909C0.758142 3.77487 0.793471 3.84538 0.850907 3.89511L6.10091 8.24698C6.15128 8.28942 6.21504 8.3127 6.28091 8.3127C6.34678 8.3127 6.41053 8.28942 6.46091 8.24698L11.7259 3.89511C11.7833 3.84538 11.8187 3.77487 11.8241 3.69909C11.8296 3.62332 11.8047 3.54848 11.755 3.49104C11.7052 3.43361 11.6347 3.39828 11.559 3.39283C11.4832 3.38738 11.4083 3.41225 11.3509 3.46198Z"
                  fill="#FFA500"
                />
                <path
                  d="M6.10078 5.10071C6.15177 5.1418 6.21529 5.16421 6.28078 5.16421C6.34627 5.16421 6.40979 5.1418 6.46078 5.10071L11.7258 0.746963C11.7705 0.709519 11.8025 0.659082 11.8173 0.602641C11.8322 0.546199 11.829 0.486548 11.8084 0.431953C11.7878 0.377358 11.7508 0.330522 11.7024 0.297937C11.654 0.265351 11.5966 0.248629 11.5383 0.250088H1.03828C0.979945 0.248629 0.922601 0.265351 0.874194 0.297937C0.825787 0.330522 0.788714 0.377358 0.768112 0.431953C0.74751 0.486548 0.7444 0.546199 0.759212 0.602641C0.774023 0.659082 0.806024 0.709519 0.85078 0.746963L6.10078 5.10071Z"
                  fill="#FFA500"
                />
              </svg>
            </NavLink>
            <div
              className={`absolute top-full mt-2 left-0 w-38 p-4 rounded-b-2xl shadow-lg transition-opacity duration-500 ease-in-out transform ${
                dropdownOpen === "whoWeAre"
                  ? "opacity-100 visible translate-y-0 z-50"
                  : "opacity-0 invisible -translate-y-2"
              }`}
              style={{
                width: "152px",
                padding: "16px 0px",
                gap: "19px",
                borderRadius: "0px 0px 20px 20px",
                background: "rgba(255, 255, 255, 1)",
                boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
              }}
            >
              <NavLink
                to="/aboutUs"
                className="block text-[14px] font-medium leading-[19.12px] text-gray-800 mb-5 px-4"
                style={{ fontFamily: "Manrope", fontWeight: "500" }}
                onClick={closeDropdown}
              >
                About Us
              </NavLink>
              <NavLink
                to="/leadership"
                className="block text-[14px] font-medium leading-[19.12px] text-gray-800 mb-5 px-4"
                style={{ fontFamily: "Manrope", fontWeight: "500" }}
                onClick={closeDropdown}
              >
                Board Of Directors
              </NavLink>
              <NavLink
                to="/ourMember"
                className="block text-[14px] font-medium leading-[19.12px] text-gray-800 px-4"
                style={{ fontFamily: "Manrope", fontWeight: "500" }}
                onClick={closeDropdown}
              >
                Our Members
              </NavLink>
            </div>
          </div>
          <NavLink
            to="/whatWeDo"
            className={`text-xs  lg:text-sm xl:text-base font-medium leading-[21.86px] ${
              (isWhatWeDoPage||isTaskforcesPage)
                ? "text-[#FFA500]"
                : isLandingPage
                ? "text-white"
                :  (isEventsPage || isEventDetailsPage) 
                ? "text-white"
                : "text-gray-800"
            } hover:text-[#FFA500] flex items-center`}
            style={{ fontFamily: "Manrope", fontWeight: "500" }}
            onClick={closeDropdown}
            activeClassName="text-[#FFA500]"
          >
            What We Do
          </NavLink>
          <NavLink
            to="/events"
            className={`text-xs  lg:text-sm xl:text-base font-medium leading-[21.86px] ${
              (isEventsPage || isEventDetailsPage) 
                ? "text-[#FFA500]"
                : isLandingPage
                ? "text-white"
                :  (isTaskforcesPage) 
                ? "text-white"
                : "text-gray-800"
            } hover:text-[#FFA500] flex items-center`}
            style={{ fontFamily: "Manrope", fontWeight: "500" }}
            onClick={closeDropdown}
            activeClassName="text-[#FFA500]"
          >
            Events
          </NavLink>
          <NavLink
            to="/blog"
            className={`text-xs  lg:text-sm xl:text-base font-medium leading-[21.86px] ${
              isBlogPage
                ? "text-[#FFA500]"
                : isLandingPage
                ? "text-white"
                :  (isEventsPage || isEventDetailsPage|| isTaskforcesPage) 
                ? "text-white"
                : "text-gray-800"
            } hover:text-[#FFA500] flex items-center`}
            style={{ fontFamily: "Manrope", fontWeight: "500" }}
            onClick={closeDropdown}
            activeClassName="text-[#FFA500]"
          >
            Blog
          </NavLink>
          <div className="relative flex items-center">
            <NavLink
              to="#"
              className={`text-xs  lg:text-sm xl:text-base font-medium leading-[21.86px] ${
                isBecomeMemberPage
                  ? "text-[#FFA500]"
                  : isVolunteerPage
                  ? "text-[#FFA500]"
                  : isLandingPage
                  ? "text-white"
                  :  (isEventsPage || isEventDetailsPage|| isTaskforcesPage) 
                  ? "text-white"
                  : "text-gray-800"
              } hover:text-[#FFA500] flex items-center`}
              style={{ fontFamily: "Manrope", fontWeight: "500" }}
              onClick={() => toggleDropdown("getInvolved")}
              activeClassName="text-[#FFA500]"
            >
              Get Involved
              <svg
                width="12"
                height="9"
                viewBox="0 0 12 9"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className={`ml-2`}
              >
                <path
                  d="M11.3509 3.46198L6.28841 7.66573L1.22591 3.46198C1.16847 3.41225 1.09363 3.38738 1.01786 3.39283C0.94208 3.39828 0.871573 3.43361 0.821845 3.49104C0.772117 3.54848 0.747242 3.62332 0.752692 3.69909C0.758142 3.77487 0.793471 3.84538 0.850907 3.89511L6.10091 8.24698C6.15128 8.28942 6.21504 8.3127 6.28091 8.3127C6.34678 8.3127 6.41053 8.28942 6.46091 8.24698L11.7259 3.89511C11.7833 3.84538 11.8187 3.77487 11.8241 3.69909C11.8296 3.62332 11.8047 3.54848 11.755 3.49104C11.7052 3.43361 11.6347 3.39828 11.559 3.39283C11.4832 3.38738 11.4083 3.41225 11.3509 3.46198Z"
                  fill="#FFA500"
                />
                <path
                  d="M6.10078 5.10071C6.15177 5.1418 6.21529 5.16421 6.28078 5.16421C6.34627 5.16421 6.40979 5.1418 6.46078 5.10071L11.7258 0.746963C11.7705 0.709519 11.8025 0.659082 11.8173 0.602641C11.8322 0.546199 11.829 0.486548 11.8084 0.431953C11.7878 0.377358 11.7508 0.330522 11.7024 0.297937C11.654 0.265351 11.5966 0.248629 11.5383 0.250088H1.03828C0.979945 0.248629 0.922601 0.265351 0.874194 0.297937C0.825787 0.330522 0.788714 0.377358 0.768112 0.431953C0.74751 0.486548 0.7444 0.546199 0.759212 0.602641C0.774023 0.659082 0.806024 0.709519 0.85078 0.746963L6.10078 5.10071Z"
                  fill="#FFA500"
                />
              </svg>
            </NavLink>
            <div
              className={`absolute top-full mt-2 left-0 w-38 p-4 rounded-b-2xl shadow-lg transition-opacity duration-500 ease-in-out transform ${
                dropdownOpen === "getInvolved"
                  ? "opacity-100 visible translate-y-0 z-50"
                  : "opacity-0 invisible -translate-y-2"
              }`}
              style={{
                width: "152px",
                padding: "16px 0px",
                gap: "19px",
                borderRadius: "0px 0px 20px 20px",
                background: "rgba(255, 255, 255, 1)",
                boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
              }}
            >
              <NavLink
                to="/volunteer"
                className="block text-[14px] font-medium leading-[19.12px] text-gray-800 mb-5 px-4"
                style={{ fontFamily: "Manrope", fontWeight: "500" }}
                onClick={closeDropdown}
              >
                Become a Leader
              </NavLink>
              <NavLink
                to="/becomeMember"
                className="block text-[14px] font-medium leading-[19.12px] text-gray-800 px-4"
                style={{ fontFamily: "Manrope", fontWeight: "500" }}
                onClick={closeDropdown}
              >
                Memberships
              </NavLink>
            </div>
          </div>
          <NavLink
            to="/contact"
            className={`text-xs  lg:text-sm xl:text-base font-medium leading-[21.86px] ${
              isContactPage
                ? "text-[#FFA500]"
                : isLandingPage
                ? "text-white"
                :  (isEventsPage || isEventDetailsPage ||isTaskforcesPage) 
                ? "text-white"
                : "text-gray-800"
            } hover:text-[#FFA500] flex items-center`}
            style={{ fontFamily: "Manrope" }}
            onClick={closeDropdown}
            activeClassName="text-[#FFA500]"
          >
            Contact Us
          </NavLink>
        </div>
        {/* Hamburger Menu for Small Screens */}
        <div className="md:hidden flex items-center ml-auto">
          <button onClick={toggleMobileMenu} className="focus:outline-none">
            <div
              className="flex flex-col justify-between w-6 h-6"
              style={{ padding: "3.75px 0px", gap: "0px" }}
            >
              <span
                className="block w-6 h-0.5 bg-black"
                style={{ opacity: "1" }}
              ></span>
              <span
                className="block w-6 h-0.5 bg-black"
                style={{ opacity: "1" }}
              ></span>
              <span
                className="block w-6 h-0.5 bg-black"
                style={{ opacity: "1" }}
              ></span>
            </div>
          </button>
        </div>
        {/* Action Buttons */}
        <div className="hidden md:flex-shrink-0 md:flex items-center space-x-4 ml-auto">
          <NavLink
            to="/volunteer"
            className={`flex py-4 px-6 xl:px-10 items-center justify-center text-[13px] font-medium leading-[18px] ${
              isLandingPage ||  (isEventsPage || isEventDetailsPage||isTaskforcesPage) 
                ? "text-white border border-white rounded-full"
                : "text-custom-blue border border-custom-blue rounded-full"
            }`}
            style={{
              fontFamily: "Helvetica Neue",
              borderRadius: "30px",
            }}
            onClick={closeDropdown}
          >
            Join Us
          </NavLink>
          <NavLink
            to="/donate"
            className={`flex py-4 px-6 xl:px-10 items-center justify-center text-[14px] font-medium leading-[18px] text-white bg-custom-blue rounded-full`}
            style={{
              fontFamily: "Manrope",
              borderRadius: "30px",
            }}
            onClick={closeDropdown}
          >
            Invest
          </NavLink>
        </div>
      </div>
      {/* Mobile Menu */}
      {menuOpen && (
        <div
          className={`md:hidden fixed top-0 left-0 h-full bg-[#FFFFFF] w-full max-w-xs transform ${
            menuOpen ? "translate-x-0" : "-translate-x-full"
          } transition-transform duration-300 ease-out z-50`}
          style={{
            padding: "78px 24px 0 24px",
          }}
        >
          <div className="absolute top-4 right-4">
            <button onClick={toggleMobileMenu} className="focus:outline-none">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.75781 17.2428L12.0008 11.9998L17.2438 17.2428M17.2438 6.75684L11.9998 11.9998L6.75781 6.75684"
                  stroke="#002A57"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </div>
          <div className="flex items-center justify-between">
            <NavLink
              to="#"
              className="text-xs  lg:text-sm xl:text-base font-medium leading-[21.86px] text-[#002A57] hover:text-[#FFA500] px-4 py-2 flex items-center"
              style={{ fontFamily: "Manrope" }}
              onClick={() => toggleDropdown("whoWeAre")}
              activeClassName="text-[#FFA500]"
            >
              Who We Are
              <svg
                width="12"
                height="9"
                viewBox="0 0 12 9"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className={`ml-2 transition-transform duration-300 ${
                  dropdownOpen === "whoWeAre" ? "transform rotate-180" : ""
                }`}
              >
                <path
                  d="M11.3509 3.46198L6.28841 7.66573L1.22591 3.46198C1.16847 3.41225 1.09363 3.38738 1.01786 3.39283C0.94208 3.39828 0.871573 3.43361 0.821845 3.49104C0.772117 3.54848 0.747242 3.62332 0.752692 3.69909C0.758142 3.77487 0.793471 3.84538 0.850907 3.89511L6.10091 8.24698C6.15128 8.28942 6.21504 8.3127 6.28091 8.3127C6.34678 8.3127 6.41053 8.28942 6.46091 8.24698L11.7259 3.89511C11.7833 3.84538 11.8187 3.77487 11.8241 3.69909C11.8296 3.62332 11.8047 3.54848 11.755 3.49104C11.7052 3.43361 11.6347 3.39828 11.559 3.39283C11.4832 3.38738 11.4083 3.41225 11.3509 3.46198Z"
                  fill="#FFA500"
                />
                <path
                  d="M6.10078 5.10071C6.15177 5.1418 6.21529 5.16421 6.28078 5.16421C6.34627 5.16421 6.40979 5.1418 6.46078 5.10071L11.7258 0.746963C11.7705 0.709519 11.8025 0.659082 11.8173 0.602641C11.8322 0.546199 11.829 0.486548 11.8084 0.431953C11.7878 0.377358 11.7508 0.330522 11.7024 0.297937C11.654 0.265351 11.5966 0.248629 11.5383 0.250088H1.03828C0.979945 0.248629 0.922601 0.265351 0.874194 0.297937C0.825787 0.330522 0.788714 0.377358 0.768112 0.431953C0.74751 0.486548 0.7444 0.546199 0.759212 0.602641C0.774023 0.659082 0.806024 0.709519 0.85078 0.746963L6.10078 5.10071Z"
                  fill="#FFA500"
                />
              </svg>
            </NavLink>
          </div>
          {dropdownOpen === "whoWeAre" && (
            <div className="pl-6 space-y-0">
              <NavLink
                to="/aboutUs"
                className="block text-[14px] font-medium leading-[19.12px] text-[#002A57] hover:text-[#FFA500] py-2"
                style={{ fontFamily: "Manrope" }}
                onClick={() => {
                  closeDropdown();
                  toggleMobileMenu(); 
                }}
              >
                About Us
              </NavLink>
              <NavLink
                to="/leadership"
                className="block text-[14px] font-medium leading-[19.12px] text-[#002A57] hover:text-[#FFA500] py-2"
                style={{ fontFamily: "Manrope" }}
                onClick={() => {
                  closeDropdown();
                  toggleMobileMenu(); // Close the mobile menu
                }}
              >
                Board Of Directors
              </NavLink>
              <NavLink
                to="/ourMember"
                className="block text-[14px] font-medium leading-[19.12px] text-[#002A57] hover:text-[#FFA500] py-2"
                style={{ fontFamily: "Manrope" }}
                onClick={() => {
                  closeDropdown();
                  toggleMobileMenu(); // Close the mobile menu
                }}
              >
                Our Members
              </NavLink>
            </div>
          )}
          <NavLink
            to="/whatWeDo"
            className="block text-xs  lg:text-sm xl:text-base font-medium leading-[21.86px] text-[#002A57] hover:text-[#FFA500] px-4 py-2"
            style={{ fontFamily: "Manrope" }}
            onClick={() => {
              closeDropdown();
              toggleMobileMenu(); // Close the mobile menu
            }}
            activeClassName="text-[#FFA500]"
          >
            What We Do
          </NavLink>
          <NavLink
            to="/events"
            className="block text-xs  lg:text-sm xl:text-base font-medium leading-[21.86px] text-[#002A57] hover:text-[#FFA500] px-4 py-2"
            style={{ fontFamily: "Manrope" }}
            onClick={() => {
              closeDropdown();
              toggleMobileMenu(); 
            }}
            activeClassName="text-[#FFA500]"
          >
            Events
          </NavLink>
          <NavLink
            to="/blog"
            className="block text-xs  lg:text-sm xl:text-base font-medium leading-[21.86px] text-[#002A57] hover:text-[#FFA500] px-4 py-2"
            style={{ fontFamily: "Manrope" }}
            onClick={() => {
              closeDropdown();
              toggleMobileMenu(); 
            }}
            activeClassName="text-[#FFA500]"
          >
            Blog
          </NavLink>
          <div className="flex items-center justify-between">
            <NavLink
              to="#"
              className="text-xs  lg:text-sm xl:text-base font-medium leading-[21.86px] text-[#002A57] hover:text-[#FFA500] px-4 py-2 flex items-center"
              style={{ fontFamily: "Manrope" }}
              onClick={() => toggleDropdown("getInvolved")}
              activeClassName="text-[#FFA500]"
            >
              Get Involved
              <svg
                width="12"
                height="9"
                viewBox="0 0 12 9"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className={`ml-2 transition-transform duration-300 ${
                  dropdownOpen === "getInvolved" ? "transform rotate-180" : ""
                }`}
              >
                <path
                  d="M11.3509 3.46198L6.28841 7.66573L1.22591 3.46198C1.16847 3.41225 1.09363 3.38738 1.01786 3.39283C0.94208 3.39828 0.871573 3.43361 0.821845 3.49104C0.772117 3.54848 0.747242 3.62332 0.752692 3.69909C0.758142 3.77487 0.793471 3.84538 0.850907 3.89511L6.10091 8.24698C6.15128 8.28942 6.21504 8.3127 6.28091 8.3127C6.34678 8.3127 6.41053 8.28942 6.46091 8.24698L11.7259 3.89511C11.7833 3.84538 11.8187 3.77487 11.8241 3.69909C11.8296 3.62332 11.8047 3.54848 11.755 3.49104C11.7052 3.43361 11.6347 3.39828 11.559 3.39283C11.4832 3.38738 11.4083 3.41225 11.3509 3.46198Z"
                  fill="#FFA500"
                />
                <path
                  d="M6.10078 5.10071C6.15177 5.1418 6.21529 5.16421 6.28078 5.16421C6.34627 5.16421 6.40979 5.1418 6.46078 5.10071L11.7258 0.746963C11.7705 0.709519 11.8025 0.659082 11.8173 0.602641C11.8322 0.546199 11.829 0.486548 11.8084 0.431953C11.7878 0.377358 11.7508 0.330522 11.7024 0.297937C11.654 0.265351 11.5966 0.248629 11.5383 0.250088H1.03828C0.979945 0.248629 0.922601 0.265351 0.874194 0.297937C0.825787 0.330522 0.788714 0.377358 0.768112 0.431953C0.74751 0.486548 0.7444 0.546199 0.759212 0.602641C0.774023 0.659082 0.806024 0.709519 0.85078 0.746963L6.10078 5.10071Z"
                  fill="#FFA500"
                />
              </svg>
            </NavLink>
          </div>
          {dropdownOpen === "getInvolved" && (
            <div className="pl-6 space-y-1">
              <NavLink
                to="/volunteer"
                className="block text-[14px] font-medium leading-[19.12px] text-[#002A57] hover:text-[#FFA500] py-2"
                style={{ fontFamily: "Manrope" }}
                onClick={() => {
                  closeDropdown();
                  toggleMobileMenu(); 
                }}
                activeClassName="text-[#FFA500]"
              >
                Become a Leader
              </NavLink>
              <NavLink
                to="/becomeMember"
                className="block text-[14px] font-medium leading-[19.12px] text-[#002A57] hover:text-[#FFA500] py-2"
                style={{ fontFamily: "Manrope" }}
                onClick={() => {
                  closeDropdown();
                  toggleMobileMenu(); 
                }}
                activeClassName="text-[#FFA500]"
              >
                Memberships
              </NavLink>
            </div>
          )}
          <NavLink
            to="/contact"
            className={`block text-xs  lg:text-sm xl:text-base font-medium leading-[21.86px] ${
              isContactPage ? "text-[#FFA500]" : "text-[#002A57]"
            } hover:text-[#FFA500] px-4 py-2`}
            style={{ fontFamily: "Manrope" }}
            onClick={() => {
              closeDropdown();
              toggleMobileMenu(); 
            }}
            activeClassName="text-[#FFA500]"
          >
            Contact Us
          </NavLink>

          <div
            className="flex flex-col  "
            style={{
              width: "171px",
              height: "112px",
              paddingTop: "32px",
              paddingLeft: "14px",
              gap: "16px",
              top: "322px",
              left: "24px",
              opacity: "1", 
            }}
          >
            <NavLink
              to="/donate"
              className="flex items-center justify-center text-[14px] font-medium leading-[18px] text-white bg-custom-blue rounded-full"
              style={{
                width: "152px",
                height: "48px",
                fontFamily: "Manrope",
                padding: "16px 0",
                borderRadius: "30px",
              }}
              onClick={() => {
                closeDropdown();
                toggleMobileMenu(); 
              }}
            >
              Invest
            </NavLink>
            <NavLink
              to="/volunteer"
              className={`flex items-center justify-center text-[13px] font-medium leading-[18px] text-custom-blue border border-custom-blue rounded-full`}
              style={{
                width: "152px",
                height: "48px",
                fontFamily: "Helvetica Neue",
                padding: "16px 16px",
                borderRadius: "30px",
              }}
              onClick={() => {
                closeDropdown();
                toggleMobileMenu(); 
              }}
            >
              Join Us
            </NavLink>
          </div>
        </div>
      )}
    </nav>
  );
};

export default React.memo(Navbar);
