// // import React, { useState, useEffect } from "react";
// // import BackgroundImage from "../assets/background.png";
// // import Newsletter from "./newsLetter";

// // const WhatWeDo = () => {
// //   const [selectedButton, setSelectedButton] = useState("All Taskforces");
// //   const [taskforces, setTaskforces] = useState([]);
// //   const [loading, setLoading] = useState(true);

// //   const handleButtonClick = (buttonName) => {
// //     setSelectedButton(buttonName);
// //   };

// //   useEffect(() => {
// //     const fetchTaskforces = async () => {
// //       try {
// //         const response = await fetch(
// //           "https://brave-desire-25bf4107de.strapiapp.com/api/taskforces?populate=*",
// //           {
// //             headers: {
// //               Authorization: `Bearer ${process.env.REACT_APP_CMS_TOKEN}`,
// //             },
// //           }
// //         );

// //         if (response.ok) {
// //           const data = await response.json();
// //           setTaskforces(data.data);
// //           console.log(data.data);
// //         } else {
// //           console.error("Error fetching data");
// //         }
// //       } catch (error) {
// //         console.error("Error fetching data:", error);
// //       } finally {
// //         setLoading(false);
// //       }
// //     };

// //     fetchTaskforces();
// //   }, []);

// //   const getCategoryStyle = (category) => {
// //     switch (category) {
// //       case "Environment":
// //         return {
// //           backgroundColor: "#A1F7CC33",
// //           textColor: "#0B7B69",
// //         };
// //       case "Jobs":
// //         return {
// //           backgroundColor: "#C543C51A",
// //           textColor: "#800080",
// //         };
// //       case "Education":
// //         return {
// //           backgroundColor: "#FFF2DB",
// //           textColor: "#FFA500",
// //         };
// //       default:
// //         return {
// //           backgroundColor: "#f1f1f1",
// //           textColor: "#000",
// //         };
// //     }
// //   };

// //   const renderTaskforce = (taskforce, index) => {
// //     const imageUrl = taskforce.attributes.image_url || null;
// //     const category = taskforce.attributes.taskforce_category?.data?.attributes?.title || "Unknown";
// //     const categoryStyles = getCategoryStyle(category);

// //     return (
// //       <div
// //         key={taskforce.id}
// //         style={{
// //           width: "380px",
// //           height: "auto",
// //           marginBottom: "30px",
// //           marginRight: (index + 1) % 3 === 0 ? "0" : "30px",
// //           borderRadius: "20px",
// //           overflow: "hidden",
// //           backgroundColor: "transparent",
// //         }}
// //       >
// //         {/* Image Section */}
// //         <div
// //           style={{
// //             width: "380px",
// //             height: "270px",
// //             borderRadius: "20px 20px 0px 0px",
// //             backgroundImage: imageUrl ? `url(${imageUrl})` : "none",
// //             backgroundColor: imageUrl ? "transparent" : "#f1f1f1",
// //             backgroundSize: "cover",
// //             backgroundPosition: "center",
// //           }}
// //         ></div>

// //         {/* Content Section */}
// //         <div style={{ paddingTop: "32px" }}>
// //           <h2
// //             style={{
// //               width: "380px",
// //               height: "auto",
// //               fontFamily: "Manrope",
// //               fontSize: "24px",
// //               fontWeight: "600",
// //               lineHeight: "33.6px",
// //               color: "rgba(0, 42, 87, 1)",
// //               marginBottom: "12px",
// //             }}
// //           >
// //             {taskforce.attributes.title}
// //           </h2>
// //           <p
// //             style={{
// //               width: "380px",
// //               height: "auto",
// //               fontFamily: "Manrope",
// //               fontSize: "16px",
// //               fontWeight: "400",
// //               lineHeight: "24px",
// //               color: "rgba(67, 62, 63, 1)",
// //               marginBottom: "24px",
// //             }}
// //           >
// //             {taskforce.attributes.description}
// //           </p>

// //           {/* Learn More and Category in a Single Line */}
// //           <div
// //             style={{
// //               width: "100%",
// //               display: "flex",
// //               justifyContent: "space-between",
// //               alignItems: "center",
// //               marginTop: "16px",
// //             }}
// //           >
// //             {/* Category Tag */}
// //             <div
// //               style={{
// //                 padding: "4px 12px",
// //                 borderRadius: "200px",
// //                 backgroundColor: categoryStyles.backgroundColor,
// //                 fontFamily: "Manrope",
// //                 fontSize: "16px",
// //                 fontWeight: "400",
// //                 lineHeight: "28.8px",
// //                 textAlign: "center",
// //                 color: categoryStyles.textColor,
// //                 whiteSpace: "nowrap",
// //               }}
// //             >
// //               {category}
// //             </div>

// //             {/* Learn More Section */}
// //             <a
// //               href={`/taskforces/${taskforce.id}`}
// //               style={{
// //                 fontFamily: "Manrope",
// //                 fontSize: "16px",
// //                 fontWeight: "400",
// //                 lineHeight: "18px",
// //                 color: "rgba(255, 165, 0, 1)",
// //                 textDecoration: "none",
// //                 display: "flex",
// //                 alignItems: "center",
// //                 gap: "8px",
// //               }}
// //             >
// //               <span>Learn More</span>
// //               <svg
// //                 width="24"
// //                 height="24"
// //                 viewBox="0 0 24 24"
// //                 fill="none"
// //                 xmlns="http://www.w3.org/2000/svg"
// //               >
// //                 <path
// //                   fillRule="evenodd"
// //                   clipRule="evenodd"
// //                   d="M8.3576 16.1876C8.22997 16.4267 8.29961 16.7313 8.5297 16.8945C8.77897 17.0712 9.12262 17.0153 9.29677 16.7697L14.8429 8.9481L15.6949 13.7987L15.7127 13.8714C15.7948 14.1299 16.0581 14.2966 16.3329 14.2529C16.6322 14.2047 16.8326 13.9234 16.78 13.6249L15.6975 7.46339L15.6801 7.3916C15.6434 7.27505 15.5686 7.17496 15.4707 7.10558C15.4123 7.06417 15.3459 7.03403 15.2746 7.0173C15.0566 6.96615 14.8316 7.04999 14.7037 7.23034L8.39364 16.1293L8.3576 16.1876ZM8.44163 8.65677C8.49581 8.95552 8.7818 9.15831 9.08129 9.10855L12.4858 8.5475L12.5586 8.53046C12.8173 8.45112 12.9802 8.19164 12.9305 7.91779C12.9034 7.76841 12.8191 7.64356 12.7041 7.56202C12.5879 7.47962 12.4416 7.44096 12.2909 7.46601L8.88676 8.02646L8.81406 8.04362C8.55582 8.12337 8.39252 8.38331 8.44163 8.65677Z"
// //                   fill="#FFA500"
// //                 />
// //               </svg>
// //             </a>
// //           </div>
// //         </div>
// //       </div>
// //     );
// //   };

// //   return (
// //     <div>
// //       <div
// //         style={{
// //           position: "relative",
// //           width: "100%",
// //           height: "400px",
// //           marginTop: "80px",
// //           backgroundImage: `url(${BackgroundImage})`,
// //           backgroundSize: "cover",
// //           backgroundRepeat: "no-repeat",
// //           display: "flex",
// //           justifyContent: "center",
// //           alignItems: "center",
// //           flexDirection: "column",
// //           zIndex: 0,
// //         }}
// //       >
// //         <div
// //           style={{
// //             position: "absolute",
// //             width: "774px",
// //             height: "154px",
// //             top: "80px",
// //             left: "50%",
// //             transform: "translateX(-50%)",
// //             gap: "28px",
// //             display: "flex",
// //             justifyContent: "center",
// //             alignItems: "center",
// //             flexDirection: "column",
// //             opacity: "1",
// //           }}
// //         >
// //           <div
// //             style={{
// //               fontFamily: "Manrope",
// //               fontSize: "64px",
// //               fontWeight: "700",
// //               lineHeight: "76.8px",
// //               textAlign: "center",
// //               color: "rgba(0, 42, 87, 1)",
// //             }}
// //           >
// //             Change Through Focused Action
// //           </div>
// //         </div>
// //         <div
// //           style={{
// //             position: "absolute",
// //             width: "577px",
// //             height: "77px",
// //             top: "258px",
// //             left: "50%",
// //             transform: "translateX(-50%)",
// //             gap: "0px",
// //             display: "flex",
// //             justifyContent: "center",
// //             alignItems: "center",
// //             opacity: "1",
// //           }}
// //         >
// //           <div
// //             style={{
// //               fontFamily: "Manrope",
// //               fontSize: "18px",
// //               fontWeight: "400",
// //               lineHeight: "25.6px",
// //               textAlign: "center",
// //               color: "rgba(10, 10, 11, 1)",
// //             }}
// //           >
// //             Explore our dedicated taskforces and their vital work in promoting
// //             education, environmental justice, and economic equity within our
// //             community
// //           </div>
// //         </div>
// //       </div>

// //       <div
// //         style={{
// //           width: "100%",
// //           background: "rgba(206, 229, 254, 0.25)",
// //           paddingTop: "40px",
// //           paddingLeft: "10%",
// //           paddingRight: "10%",
// //           zIndex: 1,
// //         }}
// //       >
// //         <div
// //           style={{
// //             display: "flex",
// //             alignItems: "center",
// //             justifyContent: "space-between",
// //             width: "100%",
// //             marginBottom: "40px",
// //           }}
// //         >
// //           <button
// //             style={{
// //               width: "152px",
// //               height: "48px",
// //               padding: "16px 0px",
// //               borderRadius: "30px",
// //               background:
// //                 selectedButton === "All Taskforces"
// //                   ? "rgba(0, 42, 87, 1)"
// //                   : "transparent",
// //               color:
// //                 selectedButton === "All Taskforces"
// //                   ? "white"
// //                   : "rgba(0, 42, 87, 1)",
// //               fontFamily: "Manrope",
// //               fontSize: "13px",
// //               fontWeight: "500",
// //               lineHeight: "18px",
// //               textAlign: "center",
// //               border:
// //                 selectedButton === "All Taskforces"
// //                   ? "none"
// //                   : "0.5px solid rgba(0, 42, 87, 1)",
// //               cursor: "pointer",
// //             }}
// //             onClick={() => handleButtonClick("All Taskforces")}
// //           >
// //             All Taskforces
// //           </button>

// //           <div
// //             style={{
// //               display: "flex",
// //               gap: "20px",
// //             }}
// //           >
// //             <button
// //               style={{
// //                 width: "152px",
// //                 height: "48px",
// //                 padding: "16px 0px",
// //                 borderRadius: "30px",
// //                 border: "0.5px solid rgba(0, 42, 87, 1)",
// //                 background:
// //                   selectedButton === "Environment"
// //                     ? "rgba(0, 42, 87, 1)"
// //                     : "transparent",
// //                 color:
// //                   selectedButton === "Environment"
// //                     ? "white"
// //                     : "rgba(0, 42, 87, 1)",
// //                 fontFamily: "Manrope",
// //                 fontSize: "13px",
// //                 fontWeight: "500",
// //                 lineHeight: "18px",
// //                 textAlign: "center",
// //                 cursor: "pointer",
// //               }}
// //               onClick={() => handleButtonClick("Environment")}
// //             >
// //               Environment
// //             </button>
// //             <button
// //               style={{
// //                 width: "152px",
// //                 height: "48px",
// //                 padding: "16px 0px",
// //                 borderRadius: "30px",
// //                 border: "0.5px solid rgba(0, 42, 87, 1)",
// //                 background:
// //                   selectedButton === "Education"
// //                     ? "rgba(0, 42, 87, 1)"
// //                     : "transparent",
// //                 color:
// //                   selectedButton === "Education"
// //                     ? "white"
// //                     : "rgba(0, 42, 87, 1)",
// //                 fontFamily: "Manrope",
// //                 fontSize: "13px",
// //                 fontWeight: "500",
// //                 lineHeight: "18px",
// //                 textAlign: "center",
// //                 cursor: "pointer",
// //               }}
// //               onClick={() => handleButtonClick("Education")}
// //             >
// //               Education
// //             </button>
// //             <button
// //               style={{
// //                 width: "152px",
// //                 height: "48px",
// //                 padding: "16px 0px",
// //                 borderRadius: "30px",
// //                 border: "0.5px solid rgba(0, 42, 87, 1)",
// //                 background:
// //                   selectedButton === "Economic"
// //                     ? "rgba(0, 42, 87, 1)"
// //                     : "transparent",
// //                 color:
// //                   selectedButton === "Economic"
// //                     ? "white"
// //                     : "rgba(0, 42, 87, 1)",
// //                 fontFamily: "Manrope",
// //                 fontSize: "13px",
// //                 fontWeight: "500",
// //                 lineHeight: "18px",
// //                 textAlign: "center",
// //                 cursor: "pointer",
// //               }}
// //               onClick={() => handleButtonClick("Economic")}
// //             >
// //               Economic
// //             </button>
// //           </div>
// //         </div>

// //         <div
// //           style={{
// //             display: "flex",
// //             flexWrap: "wrap",
// //             justifyContent: "space-between",
// //           }}
// //         >
// //           {loading ? (
// //             <p>Loading taskforces...</p>
// //           ) : taskforces.length > 0 ? (
// //             taskforces.map((taskforce, index) => renderTaskforce(taskforce, index))
// //           ) : (
// //             <p>No taskforces available.</p>
// //           )}
// //         </div>
// //       </div>
// //       <Newsletter />
// //     </div>
// //   );
// // };

// // export default WhatWeDo;



// import React, { useState, useEffect } from "react";
// import BackgroundImage from "../assets/background.png";
// import Newsletter from "./newsLetter";

// const WhatWeDo = () => {
//   const [selectedButton, setSelectedButton] = useState("All Taskforces");
//   const [taskforces, setTaskforces] = useState([]);
//   const [filteredTaskforces, setFilteredTaskforces] = useState([]); // Holds filtered data
//   const [loading, setLoading] = useState(true);
//   const [fadeOut, setFadeOut] = useState(false); // For smooth transition effect

//   const handleButtonClick = (buttonName) => {
//     setSelectedButton(buttonName);

//     // Apply fade-out effect before filtering
//     setFadeOut(true);

//     // Timeout to allow fade-out before filtering
//     setTimeout(() => {
//       if (buttonName === "All Taskforces") {
//         setFilteredTaskforces(taskforces); // Show all taskforces
//       } else {
//         setFilteredTaskforces(
//           taskforces.filter(
//             (taskforce) =>
//               taskforce.attributes.taskforce_category?.data?.attributes?.title === buttonName
//           )
//         );
//       }
//       setFadeOut(false); // Fade-in effect
//     }, 300); // Adjust duration to match CSS transition
//   };

//   useEffect(() => {
//     const fetchTaskforces = async () => {
//       try {
//         const response = await fetch(
//           "https://brave-desire-25bf4107de.strapiapp.com/api/taskforces?populate=*",
//           {
//             headers: {
//               Authorization: `Bearer ${process.env.REACT_APP_CMS_TOKEN}`,
//             },
//           }
//         );

//         if (response.ok) {
//           const data = await response.json();
//           setTaskforces(data.data);
//           setFilteredTaskforces(data.data); // Initially show all taskforces
//           console.log(data.data)
//         } else {
//           console.error("Error fetching data");
//         }
//       } catch (error) {
//         console.error("Error fetching data:", error);
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchTaskforces();
//   }, []);

//   const getCategoryStyle = (category) => {
//     switch (category) {
//       case "Environment":
//         return {
//           backgroundColor: "#A1F7CC33",
//           textColor: "#0B7B69",
//         };
//       case "Jobs":
//         return {
//           backgroundColor: "#C543C51A",
//           textColor: "#800080",
//         };
//       case "Education":
//         return {
//           backgroundColor: "#FFF2DB",
//           textColor: "#FFA500",
//         };
//       default:
//         return {
//           backgroundColor: "#f1f1f1",
//           textColor: "#000",
//         };
//     }
//   };

//   const renderTaskforce = (taskforce, index) => {
//     const imageUrl =
//     taskforce.attributes.title_background?.data?.attributes?.formats?.medium?.url ||
//     taskforce.attributes.title_background?.data?.attributes?.formats?.small?.url ||
//     taskforce.attributes.title_background?.data?.attributes?.url ||
//     null; 

//     const category = taskforce.attributes.taskforce_category?.data?.attributes?.title || "Unknown";
//     const categoryStyles = getCategoryStyle(category);

//     return (
//       <div
//         key={taskforce.id}
//         style={{
//           width: "380px",
//           height: "auto",
//           marginBottom: "30px",
//           marginRight: (index + 1) % 3 === 0 ? "0" : "30px",
//           borderRadius: "20px",
//           overflow: "hidden",
//           backgroundColor: "transparent",
//           opacity: fadeOut ? 0 : 1, // Apply fade-out/fade-in effect
//           transition: "opacity 0.3s ease-in-out", // Smooth transition
//         }}
//       >
//         {/* Image Section */}
//         <div
//           style={{
//             width: "380px",
//             height: "270px",
//             borderRadius: "20px 20px 20px 20px",
//             backgroundImage: imageUrl ? `url(${imageUrl})` : "none",
//             backgroundColor: imageUrl ? "transparent" : "#f1f1f1",
//             backgroundSize: "cover",
//             backgroundPosition: "center",
//           }}
//         ></div>

//         {/* Content Section */}
//         <div style={{ paddingTop: "32px" }}>
//           <h2
//             style={{
//               width: "380px",
//               height: "auto",
//               fontFamily: "Manrope",
//               fontSize: "24px",
//               fontWeight: "600",
//               lineHeight: "33.6px",
//               color: "rgba(0, 42, 87, 1)",
//               marginBottom: "12px",
//             }}
//           >
//             {taskforce.attributes.title}
//           </h2>
//           <p
//             style={{
//               width: "380px",
//               height: "auto",
//               fontFamily: "Manrope",
//               fontSize: "16px",
//               fontWeight: "400",
//               lineHeight: "24px",
//               color: "rgba(67, 62, 63, 1)",
//               marginBottom: "24px",
//             }}
//           >
//             {taskforce.attributes.description}
//           </p>

//           {/* Learn More and Category in a Single Line */}
//           <div
//             style={{
//               width: "100%",
//               display: "flex",
//               justifyContent: "space-between",
//               alignItems: "center",
//               marginTop: "16px",
//             }}
//           >
//             {/* Category Tag */}
//             <div
//               style={{
//                 padding: "4px 12px",
//                 borderRadius: "200px",
//                 backgroundColor: categoryStyles.backgroundColor,
//                 fontFamily: "Manrope",
//                 fontSize: "16px",
//                 fontWeight: "400",
//                 lineHeight: "28.8px",
//                 textAlign: "center",
//                 color: categoryStyles.textColor,
//                 whiteSpace: "nowrap",
//               }}
//             >
//               {category}
//             </div>

//             {/* Learn More Section */}
//             <a
//               href={`/taskforces/${taskforce.id}`}
//               style={{
//                 fontFamily: "Manrope",
//                 fontSize: "16px",
//                 fontWeight: "400",
//                 lineHeight: "18px",
//                 color: "rgba(255, 165, 0, 1)",
//                 textDecoration: "none",
//                 display: "flex",
//                 alignItems: "center",
//                 gap: "8px",
//               }}
//             >
//               <span>Learn More</span>
//               <svg
//                 width="24"
//                 height="24"
//                 viewBox="0 0 24 24"
//                 fill="none"
//                 xmlns="http://www.w3.org/2000/svg"
//               >
//                 <path
//                   fillRule="evenodd"
//                   clipRule="evenodd"
//                   d="M8.3576 16.1876C8.22997 16.4267 8.29961 16.7313 8.5297 16.8945C8.77897 17.0712 9.12262 17.0153 9.29677 16.7697L14.8429 8.9481L15.6949 13.7987L15.7127 13.8714C15.7948 14.1299 16.0581 14.2966 16.3329 14.2529C16.6322 14.2047 16.8326 13.9234 16.78 13.6249L15.6975 7.46339L15.6801 7.3916C15.6434 7.27505 15.5686 7.17496 15.4707 7.10558C15.4123 7.06417 15.3459 7.03403 15.2746 7.0173C15.0566 6.96615 14.8316 7.04999 14.7037 7.23034L8.39364 16.1293L8.3576 16.1876ZM8.44163 8.65677C8.49581 8.95552 8.7818 9.15831 9.08129 9.10855L12.4858 8.5475L12.5586 8.53046C12.8173 8.45112 12.9802 8.19164 12.9305 7.91779C12.9034 7.76841 12.8191 7.64356 12.7041 7.56202C12.5879 7.47962 12.4416 7.44096 12.2909 7.46601L8.88676 8.02646L8.81406 8.04362C8.55582 8.12337 8.39252 8.38331 8.44163 8.65677Z"
//                   fill="#FFA500"
//                 />
//               </svg>
//             </a>
//           </div>
//         </div>
//       </div>
//     );
//   };

//   return (
//     <div>
//       <div
//         style={{
//           position: "relative",
//           width: "100%",
//           height: "400px",
//           marginTop: "80px",
//           backgroundImage: `url(${BackgroundImage})`,
//           backgroundSize: "cover",
//           backgroundRepeat: "no-repeat",
//           display: "flex",
//           justifyContent: "center",
//           alignItems: "center",
//           flexDirection: "column",
//           zIndex: 0,
//         }}
//       >
//         <div
//           style={{
//             position: "absolute",
//             width: "774px",
//             height: "154px",
//             top: "80px",
//             left: "50%",
//             transform: "translateX(-50%)",
//             gap: "28px",
//             display: "flex",
//             justifyContent: "center",
//             alignItems: "center",
//             flexDirection: "column",
//             opacity: "1",
//           }}
//         >
//           <div
//             style={{
//               fontFamily: "Manrope",
//               fontSize: "64px",
//               fontWeight: "700",
//               lineHeight: "76.8px",
//               textAlign: "center",
//               color: "rgba(0, 42, 87, 1)",
//             }}
//           >
//             Change Through Focused Action
//           </div>
//         </div>
//         <div
//           style={{
//             position: "absolute",
//             width: "577px",
//             height: "77px",
//             top: "258px",
//             left: "50%",
//             transform: "translateX(-50%)",
//             gap: "0px",
//             display: "flex",
//             justifyContent: "center",
//             alignItems: "center",
//             opacity: "1",
//           }}
//         >
//           <div
//             style={{
//               fontFamily: "Manrope",
//               fontSize: "18px",
//               fontWeight: "400",
//               lineHeight: "25.6px",
//               textAlign: "center",
//               color: "rgba(10, 10, 11, 1)",
//             }}
//           >
//             Explore our dedicated taskforces and their vital work in promoting
//             education, environmental justice, and economic equity within our
//             community
//           </div>
//         </div>
//       </div>

//       <div
//         style={{
//           width: "100%",
//           background: "rgba(206, 229, 254, 0.25)",
//           paddingTop: "40px",
//           paddingLeft: "10%",
//           paddingRight: "10%",
//           zIndex: 1,
//         }}
//       >
//         <div
//           style={{
//             display: "flex",
//             alignItems: "center",
//             justifyContent: "space-between",
//             width: "100%",
//             marginBottom: "40px",
//           }}
//         >
//           <button
//             style={{
//               width: "152px",
//               height: "48px",
//               padding: "16px 0px",
//               borderRadius: "30px",
//               background:
//                 selectedButton === "All Taskforces"
//                   ? "rgba(0, 42, 87, 1)"
//                   : "transparent",
//               color:
//                 selectedButton === "All Taskforces"
//                   ? "white"
//                   : "rgba(0, 42, 87, 1)",
//               fontFamily: "Manrope",
//               fontSize: "13px",
//               fontWeight: "500",
//               lineHeight: "18px",
//               textAlign: "center",
//               border:
//                 selectedButton === "All Taskforces"
//                   ? "none"
//                   : "0.5px solid rgba(0, 42, 87, 1)",
//               cursor: "pointer",
//             }}
//             onClick={() => handleButtonClick("All Taskforces")}
//           >
//             All Taskforces
//           </button>

//           <div
//             style={{
//               display: "flex",
//               gap: "20px",
//             }}
//           >
//             <button
//               style={{
//                 width: "152px",
//                 height: "48px",
//                 padding: "16px 0px",
//                 borderRadius: "30px",
//                 border: "0.5px solid rgba(0, 42, 87, 1)",
//                 background:
//                   selectedButton === "Environment"
//                     ? "rgba(0, 42, 87, 1)"
//                     : "transparent",
//                 color:
//                   selectedButton === "Environment"
//                     ? "white"
//                     : "rgba(0, 42, 87, 1)",
//                 fontFamily: "Manrope",
//                 fontSize: "13px",
//                 fontWeight: "500",
//                 lineHeight: "18px",
//                 textAlign: "center",
//                 cursor: "pointer",
//               }}
//               onClick={() => handleButtonClick("Environment")}
//             >
//               Environment
//             </button>
//             <button
//               style={{
//                 width: "152px",
//                 height: "48px",
//                 padding: "16px 0px",
//                 borderRadius: "30px",
//                 border: "0.5px solid rgba(0, 42, 87, 1)",
//                 background:
//                   selectedButton === "Education"
//                     ? "rgba(0, 42, 87, 1)"
//                     : "transparent",
//                 color:
//                   selectedButton === "Education"
//                     ? "white"
//                     : "rgba(0, 42, 87, 1)",
//                 fontFamily: "Manrope",
//                 fontSize: "13px",
//                 fontWeight: "500",
//                 lineHeight: "18px",
//                 textAlign: "center",
//                 cursor: "pointer",
//               }}
//               onClick={() => handleButtonClick("Education")}
//             >
//               Education
//             </button>
//             <button
//               style={{
//                 width: "152px",
//                 height: "48px",
//                 padding: "16px 0px",
//                 borderRadius: "30px",
//                 border: "0.5px solid rgba(0, 42, 87, 1)",
//                 background:
//                   selectedButton === "Economic"
//                     ? "rgba(0, 42, 87, 1)"
//                     : "transparent",
//                 color:
//                   selectedButton === "Economic"
//                     ? "white"
//                     : "rgba(0, 42, 87, 1)",
//                 fontFamily: "Manrope",
//                 fontSize: "13px",
//                 fontWeight: "500",
//                 lineHeight: "18px",
//                 textAlign: "center",
//                 cursor: "pointer",
//               }}
//               onClick={() => handleButtonClick("Economic")}
//             >
//               Economic
//             </button>
//           </div>
//         </div>

//         <div
//           style={{
//             display: "flex",
//             flexWrap: "wrap",
//             justifyContent: filteredTaskforces.length === 2 ? "space-evenly" : "space-between", // Adjust spacing based on number of taskforces
//             width: "100%",
//           }}
//         >
//           {loading ? (
//             <p>Loading taskforces...</p>
//           ) : filteredTaskforces.length > 0 ? (
//             filteredTaskforces.map((taskforce, index) =>
//               renderTaskforce(taskforce, index)
//             )
//           ) : (
//             <p>No taskforces available.</p>
//           )}
//         </div>
//       </div>
//       <Newsletter />
//     </div>
//   );
// };

// export default WhatWeDo;


import React, { useState, useEffect } from "react";
import BackgroundImage from "../assets/background.png";
import Newsletter from "./newsLetter";
import { Link } from "react-router-dom";



const WhatWeDo = () => {
  const [selectedButton, setSelectedButton] = useState("All Taskforces");
  const [taskforces, setTaskforces] = useState([]);
  const [filteredTaskforces, setFilteredTaskforces] = useState([]); // Holds filtered data
  const [loading, setLoading] = useState(true);
  const [fadeOut, setFadeOut] = useState(false); // For smooth transition effect

  const handleButtonClick = (buttonName) => {
    setSelectedButton(buttonName);

    // Apply fade-out effect before filtering
    setFadeOut(true);

    // Timeout to allow fade-out before filtering
    setTimeout(() => {
      if (buttonName === "All Taskforces") {
        setFilteredTaskforces(taskforces); // Show all taskforces
      } else {
        setFilteredTaskforces(
          taskforces.filter(
            (taskforce) =>
              taskforce.attributes.taskforce_category?.data?.attributes?.title === buttonName
          )
        );
      }
      setFadeOut(false); // Fade-in effect
    }, 300); // Adjust duration to match CSS transition
  };

  useEffect(() => {
    const fetchTaskforces = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/api/taskforces?populate=*`,
          {
            headers: {
              Authorization: `Bearer ${process.env.REACT_APP_CMS_TOKEN}`, // Replace with your API token
            },
          }
        );

        if (response.ok) {
          const data = await response.json();
          setTaskforces(data.data);
          setFilteredTaskforces(data.data); // Initially show all taskforces
          console.log(data.data);
        } else {
          console.error("Error fetching data");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchTaskforces();
  }, []);

  const getCategoryStyle = (category) => {
    switch (category) {
      case "Environment":
        return {
          backgroundColor: "#A1F7CC33",
          textColor: "#0B7B69",
        };
      case "Jobs":
        return {
          backgroundColor: "#C543C51A",
          textColor: "#800080",
        };
      case "Education":
        return {
          backgroundColor: "#FFF2DB",
          textColor: "#FFA500",
        };
      default:
        return {
          backgroundColor: "#f1f1f1",
          textColor: "#000",
        };
    }
  };

  const renderTaskforce = (taskforce, index) => {
    const imageUrl =
      taskforce.attributes.title_background?.data?.attributes?.formats?.medium?.url ||
      taskforce.attributes.title_background?.data?.attributes?.formats?.small?.url ||
      taskforce.attributes.title_background?.data?.attributes?.url ||
      null;

    const category = taskforce.attributes.taskforce_category?.data?.attributes?.title || "Unknown";
    const categoryStyles = getCategoryStyle(category);

    return (
      <div
        key={taskforce.id}
        className={`w-full sm:w-[380px] h-auto mb-[30px] rounded-[20px] overflow-hidden bg-transparent ${
          fadeOut ? "opacity-0" : "opacity-100"
        } transition-opacity duration-300`}
      >
        {/* Image Section */}
        <div
          className="w-full sm:w-[380px] h-[200px] sm:h-[270px] rounded-[20px]"
          style={{
            backgroundImage: imageUrl ? `url(${imageUrl})` : "none",
            backgroundColor: imageUrl ? "transparent" : "#f1f1f1",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        ></div>
    
        {/* Content Section */}
        <div className="pt-[16px] sm:pt-[32px]">
          <h2 className="w-full sm:w-[380px] font-primary text-[20px] sm:text-[24px] font-semibold leading-[28px] sm:leading-[33.6px] text-custom-blue mb-[12px]">
            {taskforce.attributes.title}
          </h2>
          <p className="w-full sm:w-[380px] font-primary text-[14px] sm:text-[16px] font-normal leading-[20px] sm:leading-[24px] text-custom-lightgray mb-[16px] sm:mb-[24px]">
            {taskforce.attributes.description}
          </p>
    
          {/* Learn More and Category in a Single Line */}
          <div className="w-full flex justify-between items-center mt-[16px]">
            {/* Category Tag */}
            <div
              className="px-[12px] py-[4px] rounded-full font-primary text-[14px] sm:text-[16px] font-normal leading-[24px] sm:leading-[28.8px] text-center whitespace-nowrap"
              style={{
                backgroundColor: categoryStyles.backgroundColor,
                color: categoryStyles.textColor,
              }}
            >
              {category}
            </div>
    
            {/* Learn More Section */}
            <Link
              to={`/taskforces/${taskforce.id}`}
              className="font-primary text-[14px] sm:text-[16px] font-normal leading-[18px] text-custom-orange no-underline flex items-center gap-[8px]"
            >
              <span>Learn More</span>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M8.3576 16.1876C8.22997 16.4267 8.29961 16.7313 8.5297 16.8945C8.77897 17.0712 9.12262 17.0153 9.29677 16.7697L14.8429 8.9481L15.6949 13.7987L15.7127 13.8714C15.7948 14.1299 16.0581 14.2966 16.3329 14.2529C16.6322 14.2047 16.8326 13.9234 16.78 13.6249L15.6975 7.46339L15.6801 7.3916C15.6434 7.27505 15.5686 7.17496 15.4707 7.10558C15.4123 7.06417 15.3459 7.03403 15.2746 7.0173C15.0566 6.96615 14.8316 7.04999 14.7037 7.23034L8.39364 16.1293L8.3576 16.1876ZM8.44163 8.65677C8.49581 8.95552 8.7818 9.15831 9.08129 9.10855L12.4858 8.5475L12.5586 8.53046C12.8173 8.45112 12.9802 8.19164 12.9305 7.91779C12.9034 7.76841 12.8191 7.64356 12.7041 7.56202C12.5879 7.47962 12.4416 7.44096 12.2909 7.46601L8.88676 8.02646L8.81406 8.04362C8.55582 8.12337 8.39252 8.38331 8.44163 8.65677Z"
                  fill="#FFA500"
                />
              </svg>
            </Link>
          </div>
        </div>
      </div>
    );    
  };

  return (
    <div>
      {/* Header Section */}
      <div
        className="relative w-full h-auto sm:h-[400px] mt-[80px] bg-cover bg-no-repeat flex justify-center items-center flex-col z-0 py-[60px] sm:py-0"
        style={{ backgroundImage: `url(${BackgroundImage})` }}
      >
        <div className="w-full sm:absolute sm:w-[774px] h-auto sm:h-[154px] sm:top-[80px] left-1/2 transform sm:-translate-x-1/2 gap-[28px] flex justify-center items-center flex-col opacity-100 px-4">
          <div className="font-primary text-[32px] sm:text-[64px] font-bold leading-[40px] sm:leading-[76.8px] text-center text-custom-blue">
            Change Through Focused Action
          </div>
        </div>
        <div className="w-full sm:absolute sm:w-[577px] h-auto sm:h-[77px] sm:top-[258px] left-1/2 transform sm:-translate-x-1/2 gap-0 flex justify-center items-center opacity-100 px-4 mt-4 sm:mt-0">
          <div className="font-primary text-[16px] sm:text-[18px] font-normal leading-[22px] sm:leading-[25.6px] text-center text-[#0A0A0B]">
            Explore our dedicated taskforces and their vital work in promoting education,
            environmental justice, and economic equity within our community
          </div>
        </div>
      </div>

      {/* Main Content Section */}
      <div className="w-full bg-[#CEE5FE40] pt-[40px] pl-4 pr-4 sm:pl-[10%] sm:pr-[10%] z-10">
        {/* Button Section */}
        {/* Large Screen Buttons */}
        <div className="hidden max-w-[1440px] mx-auto sm:flex items-center justify-between w-full mb-[40px] ">
          <button
            className={`w-[152px] h-[48px] py-[16px] rounded-[30px] font-primary text-[13px] font-medium leading-[18px] text-center cursor-pointer ${
              selectedButton === "All Taskforces"
                ? "bg-custom-blue text-white border-none"
                : "bg-transparent text-custom-blue border border-[0.5px] border-custom-blue"
            }`}
            onClick={() => handleButtonClick("All Taskforces")}
          >
            All Taskforces
          </button>

          <div className="flex gap-[20px]">
            <button
              className={`w-[152px] h-[48px] py-[16px] rounded-[30px] font-primary text-[13px] font-medium leading-[18px] text-center cursor-pointer ${
                selectedButton === "Environment"
                  ? "bg-custom-blue text-white border-none"
                  : "bg-transparent text-custom-blue border border-[0.5px] border-custom-blue"
              }`}
              onClick={() => handleButtonClick("Environment")}
            >
              Environment
            </button>
            <button
              className={`w-[152px] h-[48px] py-[16px] rounded-[30px] font-primary text-[13px] font-medium leading-[18px] text-center cursor-pointer ${
                selectedButton === "Education"
                  ? "bg-custom-blue text-white border-none"
                  : "bg-transparent text-custom-blue border border-[0.5px] border-custom-blue"
              }`}
              onClick={() => handleButtonClick("Education")}
            >
              Education
            </button>
            <button
              className={`w-[152px] h-[48px] py-[16px] rounded-[30px] font-primary text-[13px] font-medium leading-[18px] text-center cursor-pointer ${
                selectedButton === "Economic"
                  ? "bg-custom-blue text-white border-none"
                  : "bg-transparent text-custom-blue border border-[0.5px] border-custom-blue"
              }`}
              onClick={() => handleButtonClick("Economic")}
            >
              Economic
            </button>
          </div>
        </div>

        {/* Small Screen Buttons */}
        <div className="sm:hidden flex flex-wrap justify-center w-full mb-[40px] gap-[10px]">
          <div className="grid grid-cols-2 gap-[41px]">
            <button
              className={`w-[152px] h-[48px] py-[16px] rounded-[30px] font-primary text-[13px] font-medium leading-[18px] text-center cursor-pointer ${
                selectedButton === "All Taskforces"
                  ? "bg-custom-blue text-white border-none"
                  : "bg-transparent text-custom-blue border border-[0.5px] border-custom-blue"
              }`}
              onClick={() => handleButtonClick("All Taskforces")}
            >
              All Taskforces
            </button>
            <button
              className={`w-[152px] h-[48px] py-[16px] rounded-[30px] font-primary text-[13px] font-medium leading-[18px] text-center cursor-pointer ${
                selectedButton === "Environment"
                  ? "bg-custom-blue text-white border-none"
                  : "bg-transparent text-custom-blue border border-[0.5px] border-custom-blue"
              }`}
              onClick={() => handleButtonClick("Environment")}
            >
              Environment
            </button>
            <button
              className={`w-[152px] h-[48px] py-[16px] rounded-[30px] font-primary text-[13px] font-medium leading-[18px] text-center cursor-pointer ${
                selectedButton === "Education"
                  ? "bg-custom-blue text-white border-none"
                  : "bg-transparent text-custom-blue border border-[0.5px] border-custom-blue"
              }`}
              onClick={() => handleButtonClick("Education")}
            >
              Education
            </button>
            <button
              className={`w-[152px] h-[48px] py-[16px] rounded-[30px] font-primary text-[13px] font-medium leading-[18px] text-center cursor-pointer ${
                selectedButton === "Economic"
                  ? "bg-custom-blue text-white border-none"
                  : "bg-transparent text-custom-blue border border-[0.5px] border-custom-blue"
              }`}
              onClick={() => handleButtonClick("Economic")}
            >
              Economic
            </button>
          </div>
        </div>

        {/* Taskforce Cards Section */}
        <div
          className={`flex max-w-[1440px] mx-auto flex-wrap justify-center sm:justify-between ${
            filteredTaskforces.length === 2 ? "sm:justify-evenly" : ""
          } w-full `}
        >
          {loading ? (
            <p>Loading taskforces...</p>
          ) : filteredTaskforces.length > 0 ? (
            filteredTaskforces.map((taskforce, index) =>
              renderTaskforce(taskforce, index)
            )
          ) : (
            <p>No taskforces available.</p>
          )}
        </div>
      </div>
      <Newsletter />
    </div>
  );
};

export default WhatWeDo;
